export enum TasksStatusEnum {
  Neu = 6,
  Aktiv = 7,
  Verzögert = 8,
  Gelöst = 9,
  Fertig = 14,
}

export enum TaskType {
  einmalig = 1,
  wiederkehrend = 2,
}

export enum TaskTypeName {
  Normal = "normal",
  Nachverfolgen = "follow_up",
}

export enum RepeatedType {
  wöchentlich = 4,
  monatlich = 5,
  zweiwöchentlich = 18,
  quartalsweise = 19,
  semester = 20,
  jährlich = 21,
}
