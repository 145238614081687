import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  List,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../layout/theme";
import { FileCategoryKunden } from "../../models/download-model";
import {
  brochureSection,
  chemieSection,
  enthSection,
  filterSection,
  umkehrosmoseSection,
  dosiertechnikSection,
  industrialWaterSection,
  CoraconSection,
} from "../../data/download-lists";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import FileItemComponent from "../download-area/FileItemComponent";


const renderFileCategoryList = (
  categories: FileCategoryKunden[],
  theme: any,
  type: "nested" | "normal",
  color: string,
  secondaryColor: string
) => {
  const colors = tokens(theme.palette.mode);

  return (
    <>
      {type === "nested" ? (
        categories.map((file, fileIndex) => (
          <Accordion
            disableGutters
            sx={{ backgroundColor: color }}
            key={fileIndex}
          >

            <AccordionDetails sx={{ backgroundColor: secondaryColor }}>
              {/* Render Main Category File */}
              {file.fileUrl && (
                <a
                  href={file.fileUrl}
                  download
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {file.germanTitle}
                </a>
              )}

              {/* Render Subsections */}
              {file.subSections && file.subSections.length > 0 && (
                <div >
                  {file.subSections.map((subSection, subIndex) => (
                    <Accordion
                      sx={{ backgroundColor: colors.primary[400] }}
                      disableGutters
                      key={`Accordion-item-${subIndex}`}>
                      <AccordionSummary expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel-${subIndex}-content`}
                        id={`panel-${subIndex}-header`}>

                        <Typography variant="h5">
                          {subSection.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? colors.primary[500]
                            : colors.primary[900],
                      }}>
                        {subSection.files?.map((subFile, subFileIndex) => (
                          <FileItemComponent item={subFile} index={subFileIndex} />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              )}
            </AccordionDetails>
          </Accordion >
        ))
      ) : (
        <List>
          {categories.map((file, fileIndex) => (
            <FileItemComponent item={{ fileName: file.germanTitle, fileUrl: file.fileUrl || '' }} index={fileIndex} />
          ))}
        </List>
      )}
    </>
  );
};

const renderAccordion = (
  title: string,
  categories: FileCategoryKunden[],
  theme: any,
  type: "nested" | "normal",
) => {

  const colors = tokens(theme.palette.mode);
  const color = colors.primary[400];
  const secondaryColor = theme.palette.mode === "dark"
    ? colors.primary[500]
    : colors.primary[900];

  return (
    <Accordion disableGutters sx={{ backgroundColor: color }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}>
        <Typography
          variant="h5"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: { xs: "normal", sm: "nowrap" }, // Responsive text wrapping
            wordBreak: { xs: "break-word", sm: "normal" }, // Break long words on small screens
            maxWidth: { xs: "200px", sm: "none" }, // Restrict width on small screens
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: secondaryColor }}>
        {renderFileCategoryList(categories, theme, type, color, secondaryColor)}
      </AccordionDetails>
    </Accordion>
  );
};

const KundenDownloads: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Paper elevation={5}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={colors.primary[400]}
        borderRadius="5px"
        marginTop="20px"
      >
        <Box
          ml={{ md: "0px", xs: "0px" }}
          mt="20px"
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            minHeight: "93vh",
            justifyContent: { md: "space-around", xs: "flex-start" },
            gap: "30px",
            padding: "20px",
          }}
        >
          <Box sx={{ flex: { md: 0.5, xs: 0 } }}>
            <Typography variant="h4" gutterBottom>
              Industriewasser
            </Typography>
            {renderAccordion("Ausschreibungstexte", industrialWaterSection, theme, "nested")}
            {renderAccordion("Broschüren", brochureSection, theme, "normal")}
          </Box>
          <Box sx={{ flex: { md: 0.5, xs: 0 } }}>
            <Typography variant="h4" sx={{ mb: "6px" }}>
              Coracon
            </Typography>
            {renderAccordion("Ausschreibungstexte", CoraconSection, theme, "nested")}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default KundenDownloads;
