import BoxWrap from "../box-wrap";
import changePasswordValidation from "../../../validation/change-password";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "../custom-button";
import FormTextField from "../form-text-field";
import React, { FunctionComponent } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Formik } from "formik";
import { Grid, SelectChangeEvent } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../alerts";
import { useChangeMyPassword, useChangePassword } from "../../../api/users";

interface ChangePasswordFormProps {
  userId?: string;
}

const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = (
  props: ChangePasswordFormProps
) => {
  const navigate: NavigateFunction = useNavigate();

  const userId: string | undefined = props.userId;
  const changedPasswordMutation = useChangePassword(userId);
  const changeMyPasswordMutation = useChangeMyPassword();

  //Form initial values
  const formInitialValues: Partial<any> = {
    password: "",
    password_confirmation: "",
  };

  const handleFormSubmit = async (values: any) => {
    try {
      if (userId) {
        await changedPasswordMutation.mutateAsync(values);
      } else {
        await changeMyPasswordMutation.mutateAsync(values);
      }
      await showSuccessAlert(`das Passwort wurde erfolgreich geändert`);
      navigate( userId ? '/users' : '/settings');
    } catch (error: any) {
      const { data } = error.response;
      showErrorAlert(data.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={changePasswordValidation}
        onSubmit={handleFormSubmit}
        validateOnBlur={false}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue, resetForm }) => {
          const handleChange = (
            e:
              | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              | SelectChangeEvent<any>
          ) => {
            const { name, value } = e.target;
            setFieldValue(name, value);
          };
          return (
            <form onSubmit={handleSubmit}>
              <BoxWrap mt="40px" padding={2}>
                <Grid container alignItems="flex-start" spacing={2} my="10px">
                  <Grid item xs={12} md={12} lg={6} py={1}>
                    <FormTextField
                      type="password"
                      name="password"
                      label="Passwort"
                      value={values.password || ""}
                      required={true}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} py={1}>
                    <FormTextField
                      type="password"
                      name="password_confirmation"
                      label="Passwort Bestätigung"
                      value={values.password_confirmation || ""}
                      required={true}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </BoxWrap>
              <BoxWrap
                hasBackground={false}
                display="flex"
                justifyContent="end"
                gap="20px"
                py={1}
                mt="10px"
              >
                <CustomButton
                  startIcon={<ClearIcon />}
                  label="Abbrechen"
                  color="inherit"
                  handleOnClick={() => {
                    resetForm();
                    navigate(userId ? `/users/${userId}` : '/settings');
                  }}
                />
                <CustomButton
                  startIcon={<RestartAltIcon />}
                  type="submit"
                  disabled={isSubmitting}
                  label={
                    isSubmitting ? "Kennwort ändern..." : "Kennwort ändern"
                  }
                />
              </BoxWrap>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangePasswordForm;
