import axios from "axios";
import { BASE_URL, token } from "./index";
import { showErrorAlert } from "../components/shared/alerts";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    //This header instruct the browser on how long the assets should be cached
    "Cache-Control": "max-age=31536000",
    Authorization: `Bearer ${token}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      window.location.href = "/forbidden"; // Redirect to the forbidden page
    }
    else {//Centralize the display of errors returned from the backend
      const errorMessage =
        error.response?.data?.message ||
        (error.request
          ? "Keine Antwort vom Server erhalten."
          : "Ein unerwarteter Fehler ist aufgetreten.");
      
      // Show the error modal
      showErrorAlert(errorMessage);
    }

    return Promise.reject(error);
  }
);

export const ApiService = {
  get: async (url: string, params?: object) => {
    try {
      const response = await axiosInstance.get(url, { ...params });
      return response.data;
    } catch (error) {
      // Re-throw the error to allow the caller to handle it
      throw error;
    }
  },
  post: async (url: string, data?: object) => {
    try {
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postFormData: async (url: string, formData: FormData) => {
    try {
      const response = await axiosInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  put: async (url: string, data?: object) => {
    try {
      const response = await axiosInstance.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  patch: async (url: string, data?: object) => {
    try {
      const response = await axiosInstance.patch(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url: string) => {
    try {
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
