import React from "react";
import { Box } from "@mui/material";

const LayoutBox = ({ children }: any) => {
  return (
    <Box width="100%" m="10px 10px">
      <Box justifyContent="space-between">{children}</Box>
    </Box>
  );
};

export default LayoutBox;
