import React from "react";
import { File } from "../../models/download-model";
import {
    IconButton,
    ListItem,
    ListItemText,
    useTheme,
    Divider
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

const FileItemComponent = ({ item, index }: { item: File, index: number }) => {
    const theme = useTheme();
    const { fileName, fileUrl } = item;

    return (
        <React.Fragment key={index}>
            <a
                href={fileUrl}
                download
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <ListItem sx={{
                    listStyle: 'outside',
                    textDecoration: 'none',
                    width: 'fit-content',
                }}>
                    <ListItemText
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: { xs: 'normal', sm: 'nowrap' },
                            wordBreak: { xs: 'break-word', sm: 'normal' },
                            maxWidth: { xs: '200px', sm: 'none' },
                            flex: 'none',
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                        }}
                        primary={fileName}
                    />
                    <IconButton edge="end" aria-label="download">
                        <GetAppIcon />
                    </IconButton>
                </ListItem>
            </a>
            <Divider orientation="horizontal" variant="middle" />
        </React.Fragment>
    );
};

export default FileItemComponent;