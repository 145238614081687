import React from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import Header from "../shared/header";
import NotificationsSettings from "../notifications/notifications-settings";
import LinkButton from "../shared/link-button";
import LockIcon from "@mui/icons-material/Lock";

const SettingsPage = () => {
  return (
    <>
      <Box
        ml="20px"
        mt="65px"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "93vh",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Einstellungen" subtitle="" />
        </Box>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <NotificationsSettings />
          </Grid>
          <Grid item md={12} xs={12}>
            <LinkButton
              color="warning"
              startIcon={<LockIcon />}
              label="Mein Kennwort ändern"
              to={"/change-password"}
            />
          </Grid>
        </Grid>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center">
              {"Copyright © "}
              {"Version - 0.2.2.20250309d"}
              {"."}
            </Typography>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default SettingsPage;
