import Swal from "sweetalert2";

export const showSuccessAlert = async (
  body: string | undefined,
  title?: string | undefined,
  icon: boolean | undefined = true,
  isHtml: boolean | undefined = false
) => {
  const mode = localStorage.getItem("joy-mode");
  const customClass = {
    popup: "swal2-popup-dark ",
    title: "swal2-title-dark",
    confirmButton: "swal2-confirm-dark",
    cancelButton: "swal2-cancel-dark",
  };
  try {
    await Swal.fire({
      icon: icon ? "success" : undefined,
      title: title,
      confirmButtonColor: "#a5dc86",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      ...(isHtml ? { html: body } : { text: body }),
      customClass: mode === "dark" ? { ...customClass } : {},
    });
  } catch (err) {
    console.error("An error occurred while showing success alert:", err);
  }
};

export const showErrorAlert = async (
  body: string | undefined,
  title?: string | undefined,
  icon: boolean | undefined = true,
  isHtml: boolean | undefined = false
) => {
  const mode = localStorage.getItem("joy-mode");
  const customClass = {
    popup: "swal2-popup-dark ",
    title: "swal2-title-dark",
    confirmButton: "swal2-confirm-dark",
    cancelButton: "swal2-cancel-dark",
  };
  try {
    await Swal.fire({
      icon: icon ? "error" : undefined,
      title: title,
      confirmButtonColor: "#f27474",
      ...(isHtml ? { html: body } : { text: body }),
      customClass: mode === "dark" ? { ...customClass } : {},
    });
  } catch (err) {
    console.error("An error occurred while showing error alert:", err);
  }
};

export const showWarningAlert = async (
  body: string | undefined,
  title?: string | undefined,
  icon: boolean | undefined = true,
  isHtml: boolean | undefined = false
) => {
  const mode = localStorage.getItem("joy-mode");
  const customClass = {
    popup: "swal2-popup-dark ",
    title: "swal2-title-dark",
    confirmButton: "swal2-confirm-dark",
    cancelButton: "swal2-cancel-dark",
  };
  try {
    await Swal.fire({
      icon: icon ? "warning" : undefined,
      title: title,
      confirmButtonColor: "#f8bb86",
      ...(isHtml ? { html: body } : { text: body }),
      customClass: mode === "dark" ? { ...customClass } : {},
    });
  } catch (err) {
    console.error("An error occurred while showing warning alert:", err);
  }
};

export const showConfirmationAlert = async (
  title: string,
  confirmButtonText: string,
  denyButtonText: string,
  onConfirm: () => void
) => {
  const mode = localStorage.getItem("joy-mode");
  const customClass = {
    popup: "swal2-popup-dark ",
    title: "swal2-title-dark",
    confirmButton: "swal2-confirm-dark",
    cancelButton: "swal2-cancel-dark",
  };
  try {
    const result = await Swal.fire({
      title: title,
      showDenyButton: true,
      confirmButtonText: confirmButtonText,
      denyButtonText: denyButtonText,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      customClass: mode === "dark" ? { ...customClass } : {},
    });

    if (result.isConfirmed) {
      onConfirm();
    }
  } catch (err) {
    console.error("An error occurred while showing confirmation alert:", err);
  }
};

export const showError404Alert = () => {
  const mode = localStorage.getItem("joy-mode");
  const customClass = {
    popup: "swal2-popup-dark ",
    title: "swal2-title-dark",
    confirmButton: "swal2-confirm-dark",
    cancelButton: "swal2-cancel-dark",
  };
  return Swal.fire({
    icon: "error",
    title: "404 Nichtgefunden",
    text: "Hoppla... Die von Ihnen gesuchte Seite konnte nicht gefunden werden!",
    confirmButtonColor: "#f27474",
    backdrop: "static",
    allowOutsideClick: false,
    customClass: mode === "dark" ? { ...customClass } : {},
  });
};
