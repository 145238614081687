import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../layout/theme";
import logo from '../../assets/images/aqualogo.png';
type Props = {
  title?: string;
  subtitle?: string;
  showLogo?: boolean;
};
const Header = ({ title, subtitle, showLogo }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Box mb="10px">
        {showLogo ? (
          <Box
            component="img"
            src={logo}
            alt="Careblue Logo"
            sx={{
              height: 100,
              width: 'auto',
              marginBottom: 2,
            }}
          />
        ) : (
          title && (
            <Typography
              variant="h3"
              color={colors.grey[100]}
              fontWeight="bold"
            >
              {title}
            </Typography>
          )
        )}

        {subtitle && (
          <Typography
            variant="h5"
            color={colors.blueAccent[300]}
            fontWeight="bold"
            p="1em"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default Header;
