import InputAdornment from "@mui/material/InputAdornment";
import React, { FunctionComponent } from "react";
import { tokens } from "../layout/theme";
import { TrafficLightParametersModel } from "../../models/traffic-light/traffic-light.model";
import { useField } from "formik";
import {
  FormControl,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";

import {
  TrafficDataTooltip,
  MikroDataTooltip,
} from "../../utils/form-field-icons";
interface FormTextFieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  value?: unknown;
  endAdornmentText?: string;
  multiline?: boolean;
  minRows?: number | string;
  trafficData?: TrafficLightParametersModel;
  mikroData?: any;
  key?: any;
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<any>
  ) => void;
}

const FormTextField: FunctionComponent<FormTextFieldProps> = ({
  name,
  type,
  value,
  required = false,
  disabled = false,
  endAdornmentText = "",
  multiline = false,
  minRows = "2",
  trafficData,
  mikroData,
  onChange,
  ...props // Removed key from destructuring here
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [field, meta] = useField(name);
  const ref = React.useRef();

  if (ref.current && type === 'number') {
    // @ts-ignore
    ref.current.addEventListener("wheel", (e: any) => {
      e.preventDefault();
      return false;
    });
  }

  const configTextField = {
    ...field,
    ...props,
    type: type,
    value: value,
    required: required,
    disabled: disabled,
    multiline: multiline,
    minRows: minRows,
    onChange: onChange,
    error: !!meta.touched && !!meta.error,
    helperText: meta.touched && meta.error ? meta.error : undefined,
  };

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <TextField
          inputRef={ref}
          variant="outlined"
          fullWidth
          {...configTextField}
          FormHelperTextProps={{ style: { minHeight: "20px" } }}
          sx={{
            borderRadius: 2,
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.blueAccent[600],
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color:
                theme.palette.mode === "dark" ? "white" : colors.primary[300],
            },
            "& .MuiFormHelperText-root.Mui-error": {
              marginLeft: 0,
            },
            "& .MuiOutlinedInput-root": {
              color: colors.primary[100],
            },
          }}
          inputProps={{ lang: "de" }}
          InputProps={{
            endAdornment:
              endAdornmentText || trafficData || mikroData ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {trafficData && (
                    <TrafficDataTooltip
                      value={value}
                      trafficData={trafficData}
                      colors={colors}
                    />
                  )}
                  {mikroData && (
                    <MikroDataTooltip
                      value={value}
                      trafficData={mikroData}
                      colors={colors}
                    />
                  )}
                  {endAdornmentText && (
                    <InputAdornment
                      sx={{
                        backgroundColor: "#6FB6FF",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "4px",
                        padding: "12px 16px",
                      }}
                      position="end"
                    >
                      {endAdornmentText}
                    </InputAdornment>
                  )}
                </div>
              ) : null,
          }}
        />
      </FormControl>
    </>
  );
};

export default FormTextField;
