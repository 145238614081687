import { TeamMember, Building_dep } from "../utils/app-types";
import { GridLocaleText } from "@mui/x-data-grid";

export const mockDataBuildings: Building_dep[] = [
  {
    id: 1,
    name: "Munich",
    contact_person: "Louay",
  },
  {
    id: 2,
    name: "Munich",
    contact_person: "Louay",
  },
  {
    id: 3,
    name: "Munich",
    contact_person: "Louay",
  },
  {
    id: 4,
    name: "Munich",
    contact_person: "Louay",
  },
  {
    id: 5,
    name: "Munich",
    contact_person: "Louay",
  },
];

export const mockDataTeam: TeamMember[] = [
  {
    id: 1,
    name: "Jon Snow",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    access: "admin",
  },
  {
    id: 10,
    name: "Harvey Roxie",
    access: "admin",
  },
];

export const parameters = [
  "pH-Wert bei 20°C",
  "Leitfähigkeit bei 25°C",
  "Temperatur",
  "Gesamthärte (als CaO)",
  "Gesamthärte",
  "Karbonathärte",
  "Calcium",
  "Natrium",
  "Magnesium",
  "Chlorid",
  "Sulfat",
  "Phosphat gesamt als PO4",
  "ortho-Phosphat",
  "Eisen",
  "Kupfer",
  "Molybdän",
  "Zink",
  "Kieselsäure (SiO2)",
  "Silicium als SiO2",
  "Basekapazität Kb8,2",
  "Mangan",
  "Aluminium",
  "Kalium",
  "Nitrat",
  "Sulfit",
  "Ammonium",
  "TOC",
  "Blei",
  "Chrom",
  "Nickel",
  "Trübung",
  "Nitrit",
  "AOX",
  "Konditionierungsmittel",
  "Säurekapazität bis pH 4,3 (Ks 4,3)",
  "Permanganatindex als O2",
  "Kohlenwasserstoffe (Wa)",
  "Säurekapazität KS8,2",
];

export const parameterDataFromJSON = [
  {
    parameter_name: "ph_value_at_20c",
    parameter_label: "pH-Wert bei 20°C",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "conductivity_at_25c",
    parameter_label: "Leitfähigkeit bei 25°C",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "temperature",
    parameter_label: "Temperatur",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "total_hardness_cao",
    parameter_label: "Gesamthärte (als CaO)",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "total_hardness",
    parameter_label: "Gesamthärte",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "carbonate_hardness",
    parameter_label: "Karbonathärte",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "calcium",
    parameter_label: "Calcium",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "sodium",
    parameter_label: "Natrium",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "magnesium",
    parameter_label: "Magnesium",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "chloride",
    parameter_label: "Chlorid",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "sulfates",
    parameter_label: "Sulfat",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "total_phosphate_po4",
    parameter_label: "Phosphat gesamt als PO4",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "ortho_phosphate",
    parameter_label: "ortho-Phosphat",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "iron",
    parameter_label: "Eisen",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "copper",
    parameter_label: "Kupfer",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "molybdenum",
    parameter_label: "Molybdän",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "zinc",
    parameter_label: "Zink",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "silicic_acid_sio2",
    parameter_label: "Kieselsäure (SiO2)",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "silicon_sio2",
    parameter_label: "Silicium als SiO2",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "base_capacity_kb82",
    parameter_label: "Basekapazität Kb8,2",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "manganese",
    parameter_label: "Mangan",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "aluminum",
    parameter_label: "Aluminium",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "potassium",
    parameter_label: "Kalium",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "nitrate",
    parameter_label: "Nitrat",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "sulphite",
    parameter_label: "Sulfit",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "ammonium",
    parameter_label: "Ammonium",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "toc",
    parameter_label: "TOC",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "lead",
    parameter_label: "Blei",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "chrome",
    parameter_label: "Chrom",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "nickel",
    parameter_label: "Nickel",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "turbidity",
    parameter_label: "Trübung",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "nitrite",
    parameter_label: "Nitrit",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "aox",
    parameter_label: "AOX",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "conditioning_agent",
    parameter_label: "Konditionierungsmittel",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "acid_capacity_up_to_ph_4_3",
    parameter_label: "Säurekapazität bis pH 4,3 (Ks 4,3)",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "permanganate_index_o2",
    parameter_label: "Permanganatindex als O2",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "hydrocarbons_wa",
    parameter_label: "Kohlenwasserstoffe (Wa)",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
  {
    parameter_name: "acid_capacity_ks8_2",
    parameter_label: "Säurekapazität KS8,2",
    low_risk_min: "",
    low_warning_min: "",
    normal_min: "",
    high_warning_min: "",
    high_risk_min: "",
    high_risk_max: "",
  },
];

export const translations: Partial<GridLocaleText> = {
  columnMenuHideColumn: "Spalten ausblenden",
  columnMenuSortAsc: "Aufsteigend sortieren",
  columnMenuSortDesc: "Absteigend sortieren",
  columnMenuManageColumns: "Spalten verwalten",
  columnsPanelShowAllButton: "Zeige alles",
  columnsPanelHideAllButton: "Versteck alles",
  columnsPanelTextFieldLabel: "Spalte suchen",
  columnsPanelTextFieldPlaceholder: "Spaltentitel",
  // Filter panel text
  filterPanelAddFilter: "Filter hinzufügen",
  filterPanelRemoveAll: "Alle entfernen",
  filterPanelDeleteIconLabel: "Löschen",
  filterPanelLogicOperator: "Logischer Operator",
  filterPanelOperator: "Operator",
  filterPanelOperatorAnd: "Und",
  filterPanelOperatorOr: "Oder",
  filterPanelColumns: "Spalten",
  filterPanelInputLabel: "Wert",
  filterPanelInputPlaceholder: "Suchen",
  // Filter operators text
  filterOperatorContains: "enthält",
  filterOperatorEquals: "gleicht",
  filterOperatorStartsWith: "beginnt mit",
  filterOperatorEndsWith: "endet mit",
  filterOperatorIs: "ist",
  filterOperatorNot: "ist nicht",
  filterOperatorAfter: "ist nach",
  filterOperatorOnOrAfter: "ist am oder nach",
  filterOperatorBefore: "ist vor",
  filterOperatorOnOrBefore: "ist am oder vor",
  filterOperatorIsEmpty: "ist leer",
  filterOperatorIsNotEmpty: "ist nicht leer",
  filterOperatorIsAnyOf: "ist eine von",
  columnMenuFilter: 'Suche',
  toolbarFilters: "Suche", // Change the "Filter" button label
};
