import Can from "../../../casl/Can";
import DataTable from "../../shared/table";
import Header from "../../shared/header";
import Loader from "../../shared/loader";
import useBreadcrumbStore from "../../../stores/store-breadcrumbs";
import { Box, useMediaQuery } from "@mui/material";
import { componentDetailsBreadcrumbs } from "../components-breadcrumbs";
import { Outlet, useParams } from "react-router-dom";
import {
  useFetchComponentAudits,
  useFetchComponentDetails,
} from "../../../api/components";
import { useGetPlantDetails } from "../../../api/plant";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { columns } from "./components-audits.columns";
import i18n from "../../../i18n";

interface ComponentsAuditsProps {}

/**
 * ComponentsAudits Component
 *
 * @remarks
 * The component fetches audit data from the backend for a given component and manages pagination state. Breadcrumbs are dynamically updated based on the fetched component and plant details. Additionally, permissions are managed using the `Can` component from CASL to conditionally display certain actions.
 *
 * @module ComponentsAudits
 *
 * @example
 * ```tsx
 * <ComponentsAudits />
 * ```
 */
const ComponentsAudits: FunctionComponent<ComponentsAuditsProps> = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { plantId, componentId } = useParams();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage: number = 12; // Number of items per page

  // Get Component audits data
  const {
    data: componentAuditsData,
    isLoading,
    isError,
    refetch,
  } = useFetchComponentAudits(
    componentId ? componentId.toString() : "",
    itemsPerPage,
    currentPage
  );

  const components = componentAuditsData?.data;
  const componentsTotalItems = componentAuditsData?.pager?.total;

  // Update the breadcrumb
  const updateBreadcrumb = useBreadcrumbStore(
    (state: any) => state.updateBreadcrumb
  );
  const updateBreadcrumbCallback = useCallback(updateBreadcrumb, [
    updateBreadcrumb,
  ]);

  const { data: componentDetailsData } = useFetchComponentDetails(componentId);

  // Get plant data
  const { data: plantData, isLoading: isLoadingPlant } =
    useGetPlantDetails(plantId);
  const plant = plantData?.data;
  const generalData = plant?.general;

  useEffect(() => {
    // Update the breadcrumb with both client and building information
    const clientName = generalData?.client.name;
    const clientId = Number(generalData?.client.id);
    const buildingName = generalData?.building.name;
    const buildingId = Number(generalData?.building.id);
    updateBreadcrumbCallback(
      componentDetailsBreadcrumbs({
        client: { name: clientName, id: clientId },
        building: { name: buildingName, id: buildingId },
        plant: { name: generalData?.name, id: Number(generalData?.id) },
        component: {
          name: componentDetailsData
            ? componentDetailsData?.component_type?.name
            : i18n.t("components:component"),
          id: Number(componentDetailsData?.id),
        },
      })
    );
  }, [plant, componentDetailsData, updateBreadcrumbCallback, generalData]);

  useEffect(() => {
    refetch();
  }, [currentPage]);

  if (isLoading || isLoadingPlant) {
    return <Loader />;
  }

  const handlePageChange = (newPage: number) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage + 1);
  };

  return (
    <>
      {!isError && (
        <Box
          sx={{
            marginTop: isNonMobile ? "20px" : "60px",
          }}
        >
          <Header title="Komponenten Historie" />

          <Can I="view" a="Components">
            <Box marginTop="20px">
              <DataTable
                audits={true}
                data={components}
                fieldsNames={columns}
                currentPage={currentPage - 1}
                totalItems={componentsTotalItems}
                itemsPerPage={itemsPerPage}
                boxHeight={77}
                onPageChange={handlePageChange}
              />
            </Box>
          </Can>
          <Outlet />
        </Box>
      )}
    </>
  );
};

export default ComponentsAudits;
