import GppGoodIcon from "@mui/icons-material/GppGood";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import { formatNumberToEnglish, parseGermanFloat } from "./helper";

//-----------------------------------------------------
//----Mikro light data
//-----------------------------------------------------
export const getTransformedData = (mikroThreshold: any) => {
  if (!mikroThreshold || typeof mikroThreshold !== "object") {
    // Return an empty array or handle the case when mikroThreshold is undefined or not an object.
    return [];
  }

  // Create the "pseudomonas" object
  const pseudomonasObject = {
    critical_pseudomonas: mikroThreshold?.critical_pseudomonas,
    high_pseudomonas: mikroThreshold?.high_pseudomonas,
  };

  // Add the "pseudomonas" object to the transformedData
  mikroThreshold.pseudomonas = pseudomonasObject;

  const transformedData = Object.entries(mikroThreshold).map(
    ([key, value]) => ({
      key,
      value,
    })
  );

  return transformedData;
};
export const determineMikroValueColor = (
  value: any,
  mikroData: any,
  colors: any
) => {
  if (!mikroData) {
    // Handle the case where mikroData is undefined
    return {
      color: colors.blueAccent[600],
      icon: null,
      tooltipText: "No data available.",
    };
  }

  const numberValue: number = parseGermanFloat(value);

  if (mikroData && mikroData.key === "pseudomonas" && mikroData.value) {
    const high_pseudomonas = parseFloat(mikroData.value.high_pseudomonas);
    const criticalPseudomonas = parseFloat(
      mikroData.value.critical_pseudomonas
    );

    let tooltipText = "";
    let color = colors.blueAccent[600];
    let icon = null;

    if (numberValue > criticalPseudomonas) {
      tooltipText = "Sofortmaßnahme";
      color = "red";
      icon = <WarningIcon sx={{ color: "red" }} />;
    } else if (
      numberValue > high_pseudomonas &&
      numberValue <= criticalPseudomonas
    ) {
      tooltipText = "Kontrolle";
      color = "yellow";
      icon = <WarningIcon sx={{ color: "yellow" }} />;
    }

    return {
      color,
      icon,
      tooltipText,
    };
  } else if (mikroData && mikroData.value) {
    const mikro_value = parseFloat(mikroData.value);
    let tooltipText = "";
    if (numberValue > mikro_value) {
      tooltipText = "Dies ist der Warnbereich.";
      return {
        color: "red",
        icon: <WarningIcon sx={{ color: "red" }} />,
        tooltipText,
      };
    }
  }

  return {
    color: colors.blueAccent[600],
    icon: null,
    tooltipText: "No data available.",
  };
};

// show Mikro data
export const MikroDataTooltip = (props: any) => {
  const { value, trafficData, colors } = props;
  const borderColorInfo = determineMikroValueColor(value, trafficData, colors);
  return (
    <Tooltip
      style={{
        cursor: "pointer",
        fontSize: "20px",
      }}
      title={borderColorInfo.tooltipText}
    >
      <div>{borderColorInfo.icon}</div>
    </Tooltip>
  );
};

//-----------------------------------------------------
//----Traffic light data
//-----------------------------------------------------
const isNotNull = (value: any) => {
  if (value === null || value === undefined) return false;
  return true;
};
const isWarning = (
  numberValue: number,
  low_warning_min: number,
  normal_min: number,
  high_warning_min: number,
  high_risk_min: number
) => {
  return (
    (isNotNull(low_warning_min) &&
      numberValue >= low_warning_min &&
      numberValue < normal_min) ||
    (isNotNull(high_warning_min) &&
      numberValue >= high_warning_min &&
      numberValue < high_risk_min)
  );
};

const isRisk = (
  numberValue: number,
  low_risk_min: number,
  low_warning_min: number,
  normal_min: number,
  high_risk_min: number,
  high_risk_max: number
) => {
  return (
    (isNotNull(low_risk_min) &&
      numberValue >= low_risk_min &&
      numberValue < low_warning_min) ||
    (isNotNull(low_risk_min) &&
      !isNotNull(low_warning_min) &&
      numberValue >= low_risk_min &&
      numberValue < normal_min) ||
    (numberValue >= high_risk_min && numberValue < high_risk_max) ||
    (!isNotNull(high_risk_max) && numberValue >= high_risk_min)
  );
};

const isNormal = (
  numberValue: number,
  normal_min: number,
  high_warning_min: number,
  high_risk_min: number
) => {
  return (
    numberValue >= normal_min &&
    ((isNotNull(high_warning_min) && numberValue < high_warning_min) ||
      (!isNotNull(high_warning_min) && numberValue < high_risk_min))
  );
};

export const determineBorderColor = (
  value: any,
  trafficData: any,
  colors: any
) => {
  if (!trafficData) {
    // Handle the case where trafficData is undefined
    return {
      color: colors.blueAccent[600],
      icon: null,
      tooltipText: "No data available.",
    };
  }

  let tooltipText = "";

  const numberValue: number = formatNumberToEnglish(value);

  if (
    isRisk(
      numberValue,
      trafficData["low_risk_min"],
      trafficData["low_warning_min"],
      trafficData["normal_min"],
      trafficData["high_risk_min"],
      trafficData["high_risk_max"]
    )
  ) {
    tooltipText = "This is the risk range.";
    return {
      color: "red",
      icon: <WarningIcon sx={{ color: "red" }} />,
      tooltipText,
    };
  } else if (
    isWarning(
      numberValue,
      trafficData["low_warning_min"],
      trafficData["normal_min"],
      trafficData["high_warning_min"],
      trafficData["high_risk_min"]
    )
  ) {
    return {
      color: "orange",
      icon: <WarningIcon sx={{ color: "orange" }} />,
      tooltipText: "This is the warning range.",
    };
  } else if (
    isNormal(
      numberValue,
      trafficData["normal_min"],
      trafficData["high_warning_min"],
      trafficData["high_risk_min"]
    )
  ) {
    return {
      color: "green",
      icon: <GppGoodIcon sx={{ color: "green" }} />,
      tooltipText: "This is the normal range.",
    };
  } else {
    return {
      color: colors.blueAccent[600],
      icon: null,
      tooltipText: "No data available.",
    };
  }
};

// show Traffic data
export const TrafficDataTooltip = (props: any) => {
  const { value, trafficData, colors } = props;
  const newValue = typeof value === "string" ? value.replace(".", ",") : value;
  const borderColorInfo = determineBorderColor(newValue, trafficData, colors);

  return (
    <Tooltip
      style={{
        cursor: "pointer",
        fontSize: "20px",
      }}
      title={borderColorInfo.tooltipText}
    >
      <div>{borderColorInfo.icon}</div>
    </Tooltip>
  );
};
