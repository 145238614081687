import React from "react";
import { Box } from "@mui/material";
import Header from "../shared/header";
import LoginForm from "../shared/forms/login-account";

const LoginPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" /* Adjust the height as needed */
    >
      <Box width="60%" m="20px">
        <Box textAlign="center">
          <Header
            showLogo
            subtitle="E-Mail und Passwort zur Anmeldung verwenden"
          />
        </Box>
        <LoginForm />
      </Box>
    </Box>
  );
};

export default LoginPage;
