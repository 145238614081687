import { ClientModel } from "../models/clients/client.model";
import { format, getYear, isValid, parse } from "date-fns";
import { GridFilterItem } from "@mui/x-data-grid";
import { MapModel, MarkerModel } from "../models/map/map.model";
import { Marker } from "./app-types";
import {
  showSuccessAlert,
  showErrorAlert,
  showWarningAlert,
} from "../components/shared/alerts";
import { RepeatedType } from "../models/tasks/task-status.enum";

/**
 * Transforms a filter item into a filter object with a field-value pair.
 *
 * @param filterItem - An object of type `GridFilterItem` containing the filter field and value.
 *                     The `field` represents the property name to filter by, and the `value`
 *                     represents the filter criteria.
 *
 * @returns An object with a single key-value pair where the key is the field and the value is the
 *          filter criteria. If the `value` is not a non-empty string, an empty object is returned.
 *
 * @Example
 * ```typescript
 * const filterItem = { field: "name", value: "Alice" };
 * const result = transformFilterItemToFilterObject(filterItem);
 *
 * // Result:
 * // { name: "Alice" }
 *
 * const emptyFilterItem = { field: "name", value: "" };
 * const emptyResult = transformFilterItemToFilterObject(emptyFilterItem);
 *
 * // Result:
 * // {}
 * ```
 */
export const transformFilterItemToFilterObject = (
  filterItem: GridFilterItem
): { field: string; value: any } => {
  const { field, value } = filterItem;
  const result: any = {};

  // Add filters.field and filters.value to result if conditions are met
  if (filterItem && typeof value === "string" && value !== "") {
    result[field as string] = value;
  }

  return result;
};

/**
 * Extracts marker information from building and client data into a unified array of markers.
 *
 * @param buildingData - An array of `MarkerModel` objects containing building information,
 *                       including latitude, longitude, address, and name.
 * @param clientData - A `ClientModel` object containing client information,
 *                     including latitude, longitude, address, and name.
 *
 * @returns An array of `Marker` objects where each marker contains:
 *          - `address_latitude`: The latitude of the location.
 *          - `address_longitude`: The longitude of the location.
 *          - `address`: A formatted string combining the address and name.
 *                     If the address is not available, only the name is used.
 *
 * @Example
 * ```typescript
 * const buildingData = [
 *   { address: "123 Main St", address_latitude: 40.7128, address_longitude: -74.006, name: "Building A" },
 *   { address: null, address_latitude: 34.0522, address_longitude: -118.2437, name: "Building B" },
 * ];
 * const clientData = {
 *   address: "456 Elm St",
 *   address_latitude: 37.7749,
 *   address_longitude: -122.4194,
 *   name: "Client HQ"
 * };
 *
 * const markers = extractMarkers(buildingData, clientData);
 *
 * @Result
 * // [
 * //   { address_latitude: 40.7128, address_longitude: -74.006, address: "123 Main St | Building A" },
 * //   { address_latitude: 34.0522, address_longitude: -118.2437, address: "Building B" },
 * //   { address_latitude: 37.7749, address_longitude: -122.4194, address: "456 Elm St | Client HQ" },
 * // ]
 * ```
 */
export const extractMarkers = (
  buildingData: MarkerModel[],
  clientData: ClientModel
): Marker[] => {
  const markers: MapModel[] = [];

  if (buildingData) {
    buildingData.forEach((item) => {
      const { address, address_latitude, address_longitude, name } = item;
      if (address_latitude !== null || address_latitude !== null) {
        markers.push({
          address_latitude: address_latitude,
          address_longitude: address_longitude,
          address: !address ? name : `${address} | ${name}`,
        });
      }
    });
  }

  if (clientData) {
    const { address, address_latitude, address_longitude, name } = clientData;
    if (address_latitude !== null || address_latitude !== null) {
      markers.push({
        address_latitude: address_latitude,
        address_longitude: address_longitude,
        address: !address ? name : `${address} | ${name}`,
      });
    }
  }

  return markers;
};

/**
 * Filters an object by removing keys specified in the excluded keys array.
 * Nested objects are recursively filtered to exclude the specified keys.
 *
 * @param obj - The input object to be filtered. Can contain nested objects.
 * @param excludedKeys - An array of strings representing the keys to exclude
 *                       from the resulting object.
 *
 * @returns A new object with the excluded keys removed. Nested objects are also
 *          filtered to exclude the specified keys.
 *
 * @Example
 * ```typescript
 * const obj = {
 *   id: 1,
 *   name: "John",
 *   details: {
 *     age: 30,
 *     password: "secret",
 *   },
 *   password: "12345",
 * };
 * const excludedKeys = ["password"];
 *
 * const result = filteredObject(obj, excludedKeys);
 *
 * // Result:
 * // {
 * //   id: 1,
 * //   name: "John",
 * //   details: {
 * //     age: 30,
 * //   },
 * // }
 * ```
 */
export const filteredObject = (obj: any, excludedKeys: string[]) => {
  const filteredObj: any = {};

  for (let key in obj) {
    if (excludedKeys.includes(key)) {
      continue; // Skip excluded keys
    }

    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively filter nested objects
      filteredObj[key] = filteredObject(obj[key], excludedKeys);
    } else {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
};

/**
 * Formats a snake_case string into a human-readable header name by converting
 * underscores to spaces and capitalizing the first letter of each word.
 *
 * @param name - The snake_case string to format. For example, "first_name" or "header_name".
 *
 * @returns A formatted string where each word starts with an uppercase letter,
 *          and underscores are replaced by spaces. For example, "First Name" or "Header Name".
 *
 * @Example
 * ```typescript
 * const headerName = formatHeaderName("first_name");
 * // Result: "First Name"
 *
 * const anotherHeaderName = formatHeaderName("header_name");
 * // Result: "Header Name"
 * ```
 */
export const formatHeaderName = (name: string) => {
  const words = name.split("_");
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return formattedWords.join(" ");
};

/**
 * Retrieves the value of a specified cookie by its name.
 *
 * @param name - The name of the cookie to retrieve. This can be a string or an array (though typically, it should be a string).
 *
 * @returns The value of the cookie as a string if found, or `null` if the cookie does not exist.
 *
 * @Example
 * ```typescript
 * // Assuming document.cookie = "username=JohnDoe; token=abc123";
 * const username = getCookieValue("username");
 * // Result: "JohnDoe"
 *
 * const token = getCookieValue("token");
 * // Result: "abc123"
 *
 * const missingCookie = getCookieValue("nonexistent");
 * // Result: null
 * ```
 */
export const getCookieValue = (name: string | any[]) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

// get values from cookie
export const getCookieValues = (
  cookieName: string,
  propertyName: string
): string | null => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      const cookieValue = cookie.substring(cookieName.length + 1);
      const cookieObject = JSON.parse(decodeURIComponent(cookieValue));
      const propertyValue = cookieObject[propertyName];
      return propertyValue;
    }
  }
  return null;
};

/**
 * Clears all cookies for the current domain by setting their expiration date to the past.
 *
 * This function iterates through all cookies available in the `document.cookie` string
 * and removes them by setting their value to an empty string and their expiration date
 * to `Thu, 01 Jan 1970 00:00:00 UTC`.
 *
 * @Note
 * This function only clears cookies that are accessible via JavaScript and set for the current domain and path.
 * It does not clear cookies with a different path, domain, or those marked as `HttpOnly`.
 *
 * @Example
 * ```typescript
 * // Assuming document.cookie = "username=JohnDoe; token=abc123";
 * clearAllCookies();
 *
 * // After execution, all cookies will be cleared:
 * // document.cookie = "";
 * ```
 */
export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const equalsPos = cookie.indexOf("=");
    const name = equalsPos > -1 ? cookie.substr(0, equalsPos) : cookie;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
};

export const userToken = getCookieValues("_auth_state", "access_token");
export const userFullName = getCookieValues("_auth_state", "fullName");
export const userCookieId = getCookieValues("_auth_state", "id");
export const userRole = getCookieValues("_auth_state", "role");

// Used with form when update values -> only send the updated fields
export const getChangedFormValues = <T extends Record<string, any>>(
  values: T,
  initialValues: T
) => {
  return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
    const hasChanged =
      JSON.stringify(initialValues[key as keyof T]) !== JSON.stringify(value);
    if (hasChanged) {
      acc[key as keyof T] = value;
    }

    return acc;
  }, {});
};

export const showNotification = async (
  type: string | undefined,
  body: string | undefined,
  title?: string | undefined,
  icon?: boolean | undefined,
  isHtml: boolean | undefined = false
) => {
  switch (type) {
    case "urgent":
      await showErrorAlert(body, title, icon, isHtml);
      break;
    case "normal":
      await showSuccessAlert(body, title, icon, isHtml);
      break;
    case "alert":
      await showWarningAlert(body, title, icon, isHtml);
      break;
    default:
      break;
  }
};

export function formatDateToShortDate(date: any) {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return format(date.getTime(), "yyyy-MM-dd");
  } else {
    return null;
  }
}

export function formatDateToGermanDate(date: any) {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return format(date.getTime(), "dd.MM.yyy");
  } else {
    return null;
  }
}

export function formatDate(input: string | null): string | null {
  if (!input) return null;

  const datePart = input.split(" ")[0];
  return datePart;
}

export function formatTimeToShortTime(time: any) {
  // Regular expression to check if the time is already formatted as HH:mm
  const formattedTimeRegex = /^\d{2}:\d{2}$/;

  // If the time matches the format, return it directly
  if (formattedTimeRegex.test(time)) {
    return time;
  }

  // Parse and format the time if it's not already formatted
  const parsedTime = isValid(new Date(time))
    ? new Date(time)
    : parse(time, "HH:mm", new Date());
  return format(parsedTime, "HH:mm");
}

export function formatYear(date: any) {
  return getYear(date);
}

/**
 * Maps a comma-separated string of English day abbreviations to their corresponding German day names.
 *
 * @param commaSeparatedString - A string containing comma-separated English day abbreviations (e.g., "MO,WE,FR").
 *
 * @returns An array of German day names corresponding to the provided abbreviations.
 *          If an abbreviation is not recognized, it is ignored.
 *
 * @Example
 * ```typescript
 * const germanDays = matchNumbersToDays("MO,WE,FR");
 * // Result: ["Montag", "Mittwoch", "Freitag"]
 *
 * const partialDays = matchNumbersToDays("SA,SU");
 * // Result: ["Samstag", "Sonntag"]
 *
 * const invalidDays = matchNumbersToDays("MO,XZ");
 * // Result: ["Montag"] // "XZ" is ignored as it is not a valid day abbreviation.
 * ```
 *
 * @Notes
 * - The valid English day abbreviations are:
 *   - "MO" for Monday ("Montag")
 *   - "TU" for Tuesday ("Dienstag")
 *   - "WE" for Wednesday ("Mittwoch")
 *   - "TH" for Thursday ("Donnerstag")
 *   - "FR" for Friday ("Freitag")
 *   - "SA" for Saturday ("Samstag")
 *   - "SU" for Sunday ("Sonntag")
 * - Unrecognized abbreviations are ignored.
 */
export function matchNumbersToDays(commaSeparatedString: string) {
  // Map of English day abbreviations to German day names
  const dayMapping: { [key: string]: string } = {
    SA: "Samstag",
    SU: "Sonntag",
    MO: "Montag",
    TU: "Dienstag",
    WE: "Mittwoch",
    TH: "Donnerstag",
    FR: "Freitag",
  };

  // Split the input string by commas and map each abbreviation to the German day name
  const selectedDays = commaSeparatedString
    .split(",")
    .map((abbr) => dayMapping[abbr.trim().toUpperCase()] || "Invalid Day");

  return selectedDays.join(", ");
}

// Map key with value
export const keyToValue = (key: string) => {
  // Define a mapping of keys to values
  const keyToValueMap: any = {
    ph_value_at_20c: "pH-Wert bei 20°C",
    conductivity_at_25c: "elektrische Leitfähigkeit bei 25°C",
    temperature: "Temperatur",
    total_hardness: "Gesamthärte",
    alkalinity: "Alkalität",
    sodium: "Natrium",
    magnesium: "Magnesium",
    chloride: "Chlorid",
    sulfates: "Sulfate",
    total_phosphate_po4: "Phosphat gesamt als PO4",
    date_of_sampling: "Datum der Probennahme",
    iron: "Eisen",
    copper: "Kupfer",
    zinc: "Zink",
    chrome: "Chrom",
    conditioning_agent: "Konditionierungsmittel",
    desalting_machine_fez: "FWZ am Absalzautomat",
    desalination_machine_awz: "AWZ am Absalzautomat",
    fresh_water_meter: "Frischwasserzähler",
    waste_water_meter: "Abwasserzähler",
    biocide_consumed_quantity: "Verbrauchte Menge Biozid",
    hardness_stabilizer_consumed_quantity:
      "Verbrauchte Menge Härtestabilisator",
    ph_value_20c: "pH-Wert bei 20°C",
    ph_value_desalination_machine: "pH-Wert am Absalzautomat",
    conductivity_25c: "elektrische Leitfähigkeit bei 25°C",
    conductivity_continuous_blowdown_machine: "Leitfähigkeit am Absalzautomat",
    m_value: "M-Wert",
    chloride_content: "Chloridgehalt",
    iron_content: "Eisengehalt",
    product_content: "Produktgehalt",
    pseudomonas: "Pseudomonas",
    legionella: "Legionellen",
    colony_count_at_22c: "Koloniezahl bei 22°C",
    colony_count_at_36c: "Koloniezahl bei 36°C",
    low_risk_min: "Hohes Risiko",
    low_warning_min: "Risiko",
    normal_min: "Normal von",
    high_warning_min: "Normal bis",
    high_risk_min: "Risiko",
    high_risk_max: "Hohes Risiko",
    nitrate: "Nitrat",
    sulphite: "Sulfit",
    permanganate_index_o2: "Permanganatindex als O2",
    ammonium: "Ammonium",
    hydrocarbons_wa: "Kohlenwasserstoffe (Wa)",
    toc: "TOC",
    lead: "Blei",
    nickel: "Nickel",
    turbidity: "Trübung",
    nitrite: "Nitrit",
    aox: "AOX",
    acid_capacity_ks8_2: "Säurekapazität KS8,2",
    acid_capacity_up_to_ph_4_3: "Säurekapazität bis pH 4,3 (Ks 4,3)",
    potassium: "Kalium",
    aluminum: "Aluminium",
    manganese: "Mangan",
    base_capacity_kb82: "Basekapazität Kb8,2",
    silicon_sio2: "Silicium als SiO2",
    silicic_acid_sio2: "Kieselsäure (SiO2)",
    molybdenum: "Molybdän",
    ortho_phosphate: "ortho-Phosphat",
    calcium: "Calcium",
    carbonate_hardness: "Karbonathärte",
    total_hardness_cao: "Gesamthärte (als CaO)",
    dip_slide_wert: "Dips-Slide Wert",
    silicate: "Silikat",
    deha: "DEHA",
    phosphate: "o-Phosphat",
    sulfite: "Sulfit",
    p_value: "p-Wert",
    residual_hardness: "Resthärte",
    treated_water_0_5l: "Wasserprobe",
    dip_slide: "Dipslide",
    comments: "Kommentare",
    usable_water_0_5l: "Wasserprobe",
    sample_type: "Probenart",
    disinfection_type: "Desinfektionsart",
    active_ingredient: "Biozid, Wirkstoff",
    dosing_interval_last_dosing: "Dosierintervall/letzte Dosierung",
    manual: "Manuell",
    is_pseudomonas: "Pseudomonas",
    total_colony_count: "Gesamtkoloniezahl",
    is_legionella: "Legionellen",
    tranzport_time: "Transportzeit",
    other_cause: "Sonstiger Anlass",
    temperature_monitoring: "Temperaturüberwachung",
    cooling: "Kühlung",
    usable_water: "Wasserprobe",
    dark: "Dunkel",
    monthly: "monatlich",
    time: "Uhrzeit",
    lab_report_name: "Laborbericht",
    status: "Status",
    mixed_water: "Mischwasser",
    component_id: "Komponenten-ID",
    hub: "Hub",
    frequency: "Frequenz",
    in_order: "in Ordnung",
    stock: "Vorrat",
    components: "Komponenten",
    products: "Produkte",
    amount: "Menge",
    plant_product_id: "Pflanzenprodukt-ID",
    others: "Sonstiges",
    name: "Name",
    value: "Wert",
    clouding_option: "Trübung",
    odor_option: "Geruch",
    coloring_option: "Färbung",
    why_not_in_order: "Warum nicht in ordnung",
    coloration_turbidity: "Färbung / Trübung",
    clouding_looked_at: "Trübung, betrachted",
    coloring: "Färbung",
    meko: "MEKO",
    poc: "POC",
    dynamic_parameters: "andere Chemikalien",
    traffic_light_id: "Chemikalie",
    order_number: "Bestellnummer",
    visual_inspection: "Sichtkontrolle",
    access_operation_log: "Einsicht Betriebstagebuch"
    // Add more key-value pairs as needed
  };

  // Check if the inputText exists in the mapping, return the corresponding value
  if (keyToValueMap.hasOwnProperty(key)) {
    return keyToValueMap[key];
  }

  // Return a default value or null if the inputText is not found
  return key;
};

export const adjustYear = (year: number | undefined) => {
  if (year) {
    const date = new Date();
    date.setFullYear(year);
    return date;
  }
  return null;
};

export const getDates = (data: any = [], key: string) => {
  let timeStamp = 1570606650000;
  let dates = [];

  for (let i = 0; i < data.length; i++) {
    timeStamp = new Date(data[i].date_of_sampling).getTime();
    if (data[i][key] !== null && data[i][key] !== undefined) {
      const innerArr = [timeStamp, data[i][key]];
      dates.push(innerArr);
    }
  }
  return dates;
};

export const formatNumberType = (value: number) => {
  const maximumFractionDigits = 5;
  if (value === 0) return "0";
  if (value) {
    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: maximumFractionDigits,
    }).format(value);
  }
  return "-  ";
};

export const formatNumberToEnglish = (value: string | number): number => {
  if (typeof value === "string") {
    // Convert German-formatted number to a valid float
    value = parseFloat(value.replace(/\./g, "").replace(",", "."));
  }

  if (isNaN(value)) return NaN; // Return NaN if conversion fails

  return value;
};

export const parseGermanFloat = (input: any) => {
  // Ensure input is a string
  let str = input.toString();

  // Remove dots and commas that are not part of the decimal separator
  const cleanedString = str.replace(/[^\d,-]/g, "");

  // Replace commas with dots for proper parsing
  const dotSeparatedString = cleanedString.replace(/,/g, ".");

  // Parse the float
  const floatValue = parseFloat(dotSeparatedString);

  return floatValue;
};

export const getNestedValue = (obj: any, path: any) => {
  return path.split(".").reduce((acc: any, part: any) => acc && acc[part], obj);
};

export function transformObjects(
  objects: Record<string, any>
): Record<string, any> {
  const transformed: Record<string, any> = {};

  for (const [key, value] of Object.entries(objects)) {
    if (value instanceof File) {
      // If the value is a File instance, just copy it as is
      transformed[key] = value;
    } else if (typeof value === "object" && value !== null) {
      transformed[key] = {}; // Initialize the transformed object

      for (const [innerKey, innerValue] of Object.entries(value)) {
        // Check if innerValue is an object and has 'id' and 'name' properties
        if (
          typeof innerValue === "object" &&
          innerValue !== null &&
          "id" in innerValue &&
          "name" in innerValue
        ) {
          // Keep the dynamic name and replace the object with just the 'id'
          transformed[key][innerKey] = innerValue.id;
        } else {
          // If it's not an object or doesn't have 'id' and 'name', leave it unchanged
          transformed[key][innerKey] = innerValue;
        }
      }
    } else {
      // If value is not an object, just copy it as is
      transformed[key] = value;
    }
  }

  return transformed;
}

/**
 * Converts a decimal point to a comma in the given value.
 *
 * @param {string | number} value - The value to be converted. Can be either a string or a number.
 * @returns {string} The converted value with a comma as the decimal separator.
 * - If the value is `0`, it returns `"0"`.
 * - If the value is falsy (e.g., `null` or `undefined`), it returns an empty string.
 * - Otherwise, it converts the value to a string and replaces the decimal point with a comma.
 */

export const convertPointToComma = (value: string | number) => {
  if (value === 0) return "0";
  if (!value) return "";
  return value.toString().replace(".", ",");
};

//Weekdays
export const weekDays = [
  {
    id: "MO",
    name: "Montag",
  },
  {
    id: "TU",
    name: "Dienstag",
  },
  {
    id: "WE",
    name: "Mittwoch",
  },
  {
    id: "TH",
    name: "Donnerstag",
  },
  {
    id: "FR",
    name: "Freitag",
  },
  {
    id: "SA",
    name: "Samstag",
  },
  {
    id: "SU",
    name: "Sonntag",
  },
];

// Semester Options
export const semesterOptions = [
  {
    id: "1",
    name: "Januar/Juli",
  },
  {
    id: "2",
    name: "Februar/August",
  },
  {
    id: "3",
    name: "März/September",
  },
  {
    id: "4",
    name: "April/Oktober",
  },
  {
    id: "5",
    name: "Mai/November",
  },
  {
    id: "6",
    name: "Juni/Dezember",
  },
];

//Yearly Options
export const yearlyOptions = [
  {
    id: "1",
    name: "Januar",
  },
  {
    id: "2",
    name: "Februar",
  },
  {
    id: "3",
    name: "Märzr",
  },
  {
    id: "4",
    name: "April",
  },
  {
    id: "5",
    name: "Mai",
  },
  {
    id: "6",
    name: "Juni",
  },
  {
    id: "7",
    name: "Juli",
  },
  {
    id: "8",
    name: "August",
  },
  {
    id: "9",
    name: "September",
  },
  {
    id: "10",
    name: "Oktober",
  },
  {
    id: "11",
    name: "November",
  },
  {
    id: "12",
    name: "Dezember",
  },
];

export const findRepeatedOn = (type: RepeatedType, value: string) => {
  if (!value) return undefined;
  if (type === RepeatedType.jährlich) {
    const rightValue = yearlyOptions.find((item) => item.id === value);
    return rightValue?.name;
  }
  if (type === RepeatedType.semester) {
    const rightValue = semesterOptions.find((item) => item.id === value);
    return rightValue?.name;
  }
  if (type === RepeatedType.monatlich || type === RepeatedType.quartalsweise) {
    return value;
  }
  if (
    type === RepeatedType.wöchentlich ||
    type === RepeatedType.zweiwöchentlich
  ) {
    const rightValue = weekDays.find((item) => item.id === value);
    return rightValue?.name;
  }
};

export const findRepeatedOnLabel = (type: RepeatedType) => {
  if (type === RepeatedType.jährlich || type === RepeatedType.semester)
    return "Monat";
  if (type === RepeatedType.monatlich || type === RepeatedType.quartalsweise)
    return "Datum";
  if (
    type === RepeatedType.wöchentlich ||
    type === RepeatedType.zweiwöchentlich
  )
    return "Tag";
  return "";
};

// Map key with value
export const mapVKAKeysToTrafficLight = (key: string) => {
  // Define a mapping of keys to values
  const keyToValueMap: any = {
    ph_value_20c: "ph_value_at_20c",
    conductivity_25c: "conductivity_at_25c",
    chloride_content: "chloride",
    iron_content: "iron",
    product_content: "conditioning_agent",
    temperature: "temperature",
    ph_value_desalination_machine: "ph_value_at_20c",
    conductivity_continuous_blowdown_machine: "conductivity_at_25c",
    m_value: "acid_capacity_up_to_ph_4_3",
    total_hardness: "total_hardness",
    residual_hardness: "total_hardness",
    silicate: "silicic_acid_sio2",
    deha: "deha",
    phosphate: "ortho_phosphate",
    sulfite: "sulphite",
  };

  // Check if the inputText exists in the mapping, return the corresponding value
  if (keyToValueMap.hasOwnProperty(key)) {
    return keyToValueMap[key];
  }

  // Return a default value or null if the inputText is not found
  return key;
};
