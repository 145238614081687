import BoxWrap from "../box-wrap";
import CustomButton from "../custom-button";
import ForgotPasswordFields from "../fields/forgot-password";
import forgotPasswordValidation from "../../../validation/forgotPassword";
import React from "react";
import { Formik } from "formik";
import { showErrorAlert, showSuccessAlert } from "../alerts";
import { useForgotPassword } from "../../../api/auth";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const forgotPasswordMutation = useForgotPassword();

  const initialValues = {
    email: "",
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const { email } = values;
      await forgotPasswordMutation.mutateAsync({ email });
      await showSuccessAlert("Eine E-Mail wurde erfolgreich gesendet!");
    } catch (err: any) {
      const { data } = err.response;
      showErrorAlert(data.message);
    }
  };
  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={forgotPasswordValidation}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <ForgotPasswordFields
            isNonMobile={isNonMobile}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <BoxWrap
            hasBackground={false}
            display="flex"
            justifyContent="end"
            gap="20px"
            m="0px 15px"
            py={4}
          >
            <CustomButton
              label="Zurück zur Loginseite"
              color="inherit"
              handleOnClick={() => {
                navigate(-1);
              }}
            />
            <CustomButton
              type="submit"
              disabled={isSubmitting}
              label={isSubmitting ? "Bestätigen..." : "Bestätigen"}
            />
          </BoxWrap>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
