import { FirebaseApp, initializeApp } from "firebase/app";
import { firebaseConfig, vapidKey } from "./firebaseConfig";
import { showNotification } from "../utils/helper";
import { toast } from "react-toastify";
import { token, userId } from "../api";
import "react-toastify/dist/ReactToastify.css";
import {
  getMessaging,
  Messaging,
  getToken,
  onMessage,
  MessagePayload,
} from "firebase/messaging";
import {
  setFirebaseDeviceToken,
  setNotificationAsReadOrReceived,
} from "../api/notification";

const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);
const messaging: Messaging = navigator.serviceWorker
  ? getMessaging(firebaseApp)
  : ({} as Messaging);

export const fetchToken = async () => {
  if (navigator.serviceWorker) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      registration?.active?.postMessage(
        JSON.stringify({
          type: "RECEIVED",
          base_url: process.env.REACT_APP_BASE_BACKEND_URL,
          id: userId,
          token: token,
        })
      );

      const currentToken = await getToken(messaging, {
        vapidKey: vapidKey,
        serviceWorkerRegistration: registration,
      });

      // toast.success("Connection is secured", { autoClose: 500 });
      if (userId !== "0") {
        setFirebaseDeviceToken(currentToken);
      }
    } catch (err) {
      // toast.error("Error while creating client token");
      console.log(err);
    }
  }
};

export const onMessageListener = (): void => {
  if (!navigator.serviceWorker) return;
  onMessage(messaging, (payload: MessagePayload) => {
    const notificationType = payload.data?.type;

    const toastOptions = {
      autoClose: 2000,
      onClick: async () => {
        await showNotification(
          notificationType,
          payload.data?.body,
          payload.data?.title,
          false
        );
        setNotificationAsReadOrReceived(payload.data?.id, true);
      },
      onClose: () => {
        // When the toast is clicked
        // call notification received api
        setNotificationAsReadOrReceived(payload.data?.id, false);
      },
    };

    // The app is in the foreground (visible to the user)
    switch (notificationType) {
      case "normal":
        toast.success(payload.data?.body || "New Message", {
          icon: "✅",
          ...toastOptions,
        });
        break;
      case "alert":
        toast.warn(payload.data?.body || "New Message", {
          icon: "⚠️",
          ...toastOptions,
        });
        break;
      case "urgent":
        toast.error(payload.data?.body || "New Message", {
          icon: "❌",
          ...toastOptions,
        });
        break;
      default:
        toast.info(payload.data?.body || "New Message", toastOptions);
        break;
    }
  });
};
