import { AxiosError } from "axios";
import { ApiService } from "./api.service";
import { BASE_URL } from "./index";
import {
  CreateUserModel,
  UserDetailsModel,
  UserListModel,
} from "../models/users/users.model";
import { IResponse } from "../models/response";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PasswordModel } from "../models/password/password.model";
import { ErrorTypeModel } from "../models/error/error.model";

// Get list of users
export const useFetchUsers = (
  per_page: number,
  page: number,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>,
  pagination?: 0 | 1
) => {
  const queryKey = ["users", per_page, page, JSON.stringify(filters), sort];
  const { refetch, ...queryResult } = useQuery<
    IResponse<UserListModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params = {
      per_page: per_page,
      page: page,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      pagination: pagination,
      ...filters,
    };

    const userData = await ApiService.get("/users", { params });
    return userData?.data;
  });
  return { ...queryResult, refetch };
};

// Get user details
export const useFetchUser = (id?: string) => {
  const queryKey = ["users", id];
  const { refetch, ...queryResult } = useQuery<
    IResponse<UserDetailsModel>,
    AxiosError
  >(queryKey, () => ApiService.get(`${BASE_URL}/users/${id}`), {
    staleTime: 60 * 1000,
    enabled: Boolean(id),
  });
  return { ...queryResult, refetch };
};

// Create new user
export const useCreateUser = () => {
  const createUser = useMutation<
    IResponse<CreateUserModel>,
    AxiosError<ErrorTypeModel>,
    CreateUserModel
  >(async (postData: CreateUserModel) => {
    const response = await ApiService.post(`/users`, postData);
    return response.data;
  });
  return createUser;
};

// Update existing user
export const useUpdateUser = (id?: string) => {
  const updateUser = useMutation<
    IResponse<CreateUserModel>,
    AxiosError<ErrorTypeModel>,
    CreateUserModel
  >(async (updatedData: CreateUserModel) => {
    const response = await ApiService.patch(`/users/${id}`, updatedData);
    return response.data;
  });

  return updateUser;
};

// Changed user password
export const useChangePassword = (id?: string) => {
  const changePassword = useMutation<
    IResponse<PasswordModel>,
    AxiosError<ErrorTypeModel>,
    PasswordModel
  >(async (updatedData: PasswordModel) => {
    const response = await ApiService.patch(
      `/users/${id}/change-password`,
      updatedData
    );
    return response.data;
  });
  return changePassword;
};

// Change my password
export const useChangeMyPassword = () => {
  const changePassword = useMutation<
    IResponse<PasswordModel>,
    AxiosError<ErrorTypeModel>,
    PasswordModel
  >(async (updatedData: PasswordModel) => {
    const response = await ApiService.patch(
      `/auth/change-password`,
      updatedData
    );
    return response.data;
  });
  return changePassword;
};
