import {
	Box,
	Divider,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import Header from "../shared/header";
import InfoField from "../shared/info-field";
import { tokens } from "../layout/theme";
import { useParams } from "react-router-dom";
import { useGetVisitDetails } from "../../api/tasks";
import { formatDateToGermanDate } from "../../utils/helper";
import Loader from "../shared/loader";

const VisitTaskDetails = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const isMobile = useMediaQuery("(min-width: 600px)");

	const { visitId } = useParams();

	const { data, isLoading, isError } = useGetVisitDetails(visitId);
	const visitData = data?.data;

	const infoFields = [
		{ label: "Erstellt von", value: visitData?.created_by?.first_name + " " + visitData?.created_by?.last_name },
		{ label: "Kunde", value: visitData?.client?.name },
		{
			label: "Datum",
			value: visitData?.created_at
				? formatDateToGermanDate(new Date(visitData.created_at))
				: "-",
		},
		{ label: "Kommentare", value: visitData?.comment },
	];

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			{!isError && (
				<>
					<Box
						sx={{
							marginTop: isMobile ? "60px" : "0",
						}}
					>
						<Header title="Kundenbesuch" />
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						bgcolor={colors.primary[400]}
						borderRadius="5px"
						marginTop="20px"
					>
						<Box
							display="flex"
							justifyContent="space-between"
							padding="1em"
							width="100%"
						>
							<Typography
								variant="h4"
								color={colors.grey[100]}
								fontWeight="bold"
							>
								Allgemeine Informationen
							</Typography>
						</Box>
						<Box padding="1em">
							<Grid container alignItems="center" display="flex" spacing={1}>
								<Grid
									item
									xs={12}
									md={12}
									lg={12}
									style={{ height: "100%" }}
									display="flex"
									flexDirection="column"
									paddingLeft={0}
								>
									{infoFields
										.filter(
											(field) =>
												field.value !== null &&
												field.value !== undefined &&
												field.value !== ""
										)
										.map(
											(field, index) =>
												field.value && (
													<React.Fragment key={index}>
														<InfoField
															label={field.label}
															value={field.value}
														/>
														<Divider />
													</React.Fragment>
												)
										)}
								</Grid>
							</Grid>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};

export default VisitTaskDetails;
