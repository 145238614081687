import React from "react";
import { Typography, useTheme } from "@mui/material";
import LayoutBox from "./layout-box";
import { tokens } from "../layout/theme";

interface InfoFieldProps {
  label: string;
  value?: string | number | JSX.Element; // Make value optional
  defaultValue?: string; // Add a defaultValue prop
  textColor?: string;
}

const InfoField = (props: InfoFieldProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { label, value, defaultValue = "N/A", textColor } = props; // Use defaultValue or "N/A" if value is undefined
  return (
    <>
      <LayoutBox>
        <Typography
          display="inline"
          variant="h5"
          color={colors.blueAccent[500]}
        >
          {label}:
        </Typography>
        <Typography
          display="inline"
          variant="h5"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal", // Allow text to wrap on all screen sizes
            wordBreak: "break-word", // Break long words on all screen sizes
            maxWidth: "100%", // Use 100% width of the container for all screen sizes
            paddingX: "10px",
          }}
          color={textColor ? textColor : colors.blueAccent[100]}
        >
          {value !== undefined ? value : defaultValue}
        </Typography>
      </LayoutBox>
    </>
  );
};

export default InfoField;
