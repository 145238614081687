import { FileCategory, FileCategoryKunden } from "../models/download-model";

export const fileCategories: FileCategory[] = [
  {
    germanTitle: "Anlagenaufnahme",
    englishDescription: "plant_recording",
    files: [
      {
        fileName: "AC Vorlage IW Anlagenaufnahme VKA",
        fileUrl: "/downloads/AC_Vorlage IW_Anlagenaufnahme_VKA_210420.pdf",
      },
      {
        fileName: "AC Vorlage IW Anlagenaufnahme NA",
        fileUrl: "/downloads/AC_Vorlage IW_Anlagenaufnahme_NA_210507.pdf",
      },
      {
        fileName: "Schicht Vorlage IW Anlagenaufnahme NA",
        fileUrl: "/downloads/S_Vorlage IW_Anlagenaufnahme_NA_210507.pdf",
      },
      {
        fileName: "Schicht Vorlage IW Anlagenaufnahme VKA",
        fileUrl: "/downloads/S_Vorlage IW_Anlagenaufnahme_VKA_210420.pdf",
      },
    ],
  },
  {
    germanTitle: "Bedienungsanleitungen",
    englishDescription: "operating_instructions",
    files: [{ fileName: "dummy.pdf", fileUrl: "/downloads/dummy.pdf" }],
  },
  {
    germanTitle: "Checklisten",
    englishDescription: "checklists",
    files: [
      {
        fileName: "Angebotsanforderung",
        fileUrl: "/downloads/Angebotsanforderung_V20.4_201022_ET.pdf",
      },
      {
        fileName: "Checkliste Dosierpumpe",
        fileUrl: "/downloads/Checkliste_Dosierpumpe_V20.4_201022_ET.pdf",
      },
      {
        fileName: "Checkliste Enthärtungsanlage",
        fileUrl: "/downloads/Checkliste_Enthärtungsanlage_V20.4_201022_ET.pdf",
      },
      {
        fileName: "Checkliste Absalzanlage",
        fileUrl: "/downloads/Checkliste_Absalzanlage_V20.4_201022_ET.pdf",
      },
      {
        fileName: "Checkliste Umkehrosmose",
        fileUrl: "/downloads/Checkliste_Umkehrosmose_V20.4_201022_ET.pdf",
      },
      {
        fileName: "Checkliste Projektierung: Reinigung-Entkalkung-Entrostung",
        fileUrl:
          "/downloads/Checkliste_Projektierung_Reinigung-Entkalkung-Entrostung_V20.4_201022_ET.pdf",
      },
    ],
  },
  {
    germanTitle: "Inbetriebnahmeprotokolle",
    englishDescription: "commissioning_report",
    files: [
      {
        fileName: "AC Inbetriebnahmeprotokoll Enthärtungsanlagen",
        fileUrl:
          "/downloads/AC_Inbetriebnahmeprotokoll_Enthärtungsanlagen_V22.2_220504_ET.pdf",
      },
      {
        fileName: "AC Inbetriebnahmeprotokoll Druckerhöhung",
        fileUrl:
          "/downloads/AC_Inbetriebnahmeprotokoll_Druckerhöhung_V22.2_220504_ET.pdf",
      },
      {
        fileName: "AC Inbetriebnahmeprotokoll Dosierpumpe",
        fileUrl:
          "/downloads/AC_Inbetriebnahmeprotokoll_Dosierpumpe_V22.2_220504_ET.pdf",
      },
      {
        fileName: "AC Inbetriebnahmeprotokoll Absalzanlage",
        fileUrl:
          "/downloads/AC_Inbetriebnahmeprotokoll_Absalzanlage_V22.2_220504_ET.pdf",
      },
    ],
  },
  {
    germanTitle: "Leistungsnachweise",
    englishDescription: "proof_of_performance",
    files: [
      {
        fileName: "Leistungsnachweis Montage und Reparatur",
        fileUrl: "/downloads/Leistungsnachweis Montage Reparatur_V02.pdf",
      },
      {
        fileName: "Leistungsnachweis Service",
        fileUrl: "/downloads/Leistungsnachweis Service_V03.pdf",
      },
    ],
  },
  {
    germanTitle: "Vorschriften",
    englishDescription: "rules",
    subSections: [
      {
        title: "Kesselwasser",
        files: [
          {
            fileName: "DIN EN 12953-1",
            fileUrl: "/downloads/DIN EN 12953-1_05-2012.pdf",
          },
          {
            fileName: "IAPWS Richtlinie Filmbildende Substanzen",
            fileUrl: "/downloads/IAPWS Guideline Filmbildende Substanzen.pdf",
          },
          {
            fileName: "Loos Wasser B002",
            fileUrl: "/downloads/Loos-Wasser-B002-082010.pdf",
          },
          { fileName: "TRBS 2141", fileUrl: "/downloads/TRBS-2141.pdf" },
          {
            fileName: "TRBS 2141 Änderungen",
            fileUrl: "/downloads/TRBS-2141-Aenderungen.pdf",
          },
          {
            fileName: "TRD 611 Juli 2005",
            fileUrl: "/downloads/TRD 611 Juli 2005.pdf",
          },
          {
            fileName: "TRD 611 tab1-5b",
            fileUrl: "/downloads/TRD_611-tab1-5b.pdf",
          },
          {
            fileName: "TRGS 608 Hydrazinersatz",
            fileUrl: "/downloads/TRGS 608_Hydrazinersatz.pdf",
          },
          {
            fileName: "TRGS 615",
            fileUrl: "/downloads/TRGS 615-05-2007-090818-tp.pdf",
          },
          { fileName: "TRGS 611", fileUrl: "/downloads/TRGS-611-Mai-2007.pdf" },
          {
            fileName: "Übersicht TRD Regelwerk",
            fileUrl: "/downloads/Übersicht-TRD-Regelwerk-060904-tp.pdf",
          },
          {
            fileName: "vgbe-s-010-00-2023-08-de_ebook-su__1",
            fileUrl: "/downloads/vgbe-s-010-00-2023-08-de_ebook-su__1.pdf",
          },
        ],
      },
      {
        title: "Offene Systeme",
        files: [
          { fileName: "VDMA 24649", fileUrl: "/downloads/VDMA24649.pdf" },
          {
            fileName: "VDI 2047 Blatt 2",
            fileUrl: "/downloads/VDI 2047 Blatt 2_2019-01.pdf",
          },
          {
            fileName: "VDI-MT 2047 Blatt 4",
            fileUrl: "/downloads/VDI-MT 2047 Blatt 4_2019-01.pdf",
          },
          {
            fileName: "VDI 3679 Blatt 1",
            fileUrl: "/downloads/VDI 3679 Blatt 1_07_2014.pdf",
          },
          {
            fileName: "VDI 3679 Blatt 2",
            fileUrl: "/downloads/VDI 3679 Blatt 2_2014-07.pdf",
          },
          {
            fileName: "VDI 3679 Blatt 3",
            fileUrl: "/downloads/VDI 3679 Blatt 3_2019-08.pdf",
          },
          { fileName: "VDI 4223", fileUrl: "/downloads/VDI 4223_2020-03.pdf" },
          {
            fileName: "VDI 4250 Blatt 1",
            fileUrl: "/downloads/VDI 4250 Blatt1_08-2014_150127-tp.pdf",
          },
          {
            fileName: "VDI 4259 Blatt 1",
            fileUrl: "/downloads/VDI 4259 Blatt 1_11-2019.pdf",
          },
          { fileName: "TRGS 400", fileUrl: "/downloads/TRGS-400.pdf" },
          { fileName: "TRGS 500", fileUrl: "/downloads/TRGS-500.pdf" },
          {
            fileName: "TRBA 400",
            fileUrl: "/downloads/TRBA-400 Ausgabe März 2018.pdf",
          },
          {
            fileName: "Legionellenempfehlung",
            fileUrl:
              "/downloads/legionellenempfehlung_2020_03_06_uba_format_0.pdf",
          },
          {
            fileName: "LAI-Auslegungsfragen 42-bimschv-aktualisierung-1",
            fileUrl:
              "/downloads/LAI-Auslegungsfragen-42-bimschv-aktualisierung_220912-1.pdf",
          },
          {
            fileName: "LAI-Auslegungsfragen 42-bimschv-aktualisierung",
            fileUrl:
              "/downloads/LAI-Auslegungsfragen-42-bimschv-aktualisierung_220912.pdf",
          },
          {
            fileName: "Checkliste Biostoff GBU",
            fileUrl: "/downloads/Checkliste Biostoff GBU.pdf",
          },
          {
            fileName: "Anlage 2 beschreibbar",
            fileUrl: "/downloads/Anlage 2 beschreibbar.pdf",
          },
          {
            fileName: "Agenda für Online Schulungen",
            fileUrl: "/downloads/Agenda für Online Schulungen.pdf",
          },
          { fileName: "42BImSchV", fileUrl: "/downloads/42_BImSchV_final.pdf" },
          {
            fileName: "UM-BioStV Kunz Gefährdungsbeurteilung Kühlwasseranlage",
            fileUrl:
              "/downloads/14-10-16_UM-BioStV Kunz Gefährdungsbeurteilung Kühlwasseranlage.pdf",
          },
        ],
      },
      {
        title: "Geschlossene Systeme",
        files: [
          {
            fileName: "VDI 2035 Blatt 1",
            fileUrl: "/downloads/VDI 2035 Blatt 1.pdf",
          },
          {
            fileName: "VDI 2035 Blatt 2",
            fileUrl: "/downloads/VDI 2035 Blatt 2.pdf",
          },
          { fileName: "VDI 6044", fileUrl: "/downloads/VDI_6044_V01.pdf" },
          {
            fileName: "Betriebsbuch VDI 6044 Version 3",
            fileUrl: "/downloads/2023-09-01 Betriebsbuch VDI 6044_V03.pdf",
          },
          {
            fileName: "Anlagenbuch VDI 6044 Version 1",
            fileUrl: "/downloads/2023-09-01 Anlagenbuch VDI 6044_V01.pdf",
          },
          { fileName: "VDI RL", fileUrl: "/downloads/VDI_RL_2035_2.pdf" },
        ],
      },
      {
        title: "Sonstiges",
        files: [
          {
            fileName: "Wann ist ein Sicherheitsdatenblatt erforderlich?",
            fileUrl:
              "/downloads/Wann ist ein Sicherheitsdatenblatt erforderlich.pdf",
          },
          {
            fileName: "Erneuerung der Trinkwasserverordnung",
            fileUrl: "/downloads/Erneuerung der Trinkwasserverordnung.pdf",
          },
          { fileName: "WSP", fileUrl: "/downloads/WSP.pdf" },
          {
            fileName: "VDI 2067 Blatt 2",
            fileUrl: "/downloads/VDI 2067_Blatt 22_2023-05_230628.pdf",
          },
          {
            fileName: "VDI 2070",
            fileUrl: "/downloads/VDI 2070_03-2013-150316-tp.pdf",
          },
          {
            fileName: "VDI 3810 Blatt 2",
            fileUrl: "/downloads/VDI 3810 Blatt2_05-2010-150128-tp.pdf",
          },
          {
            fileName: "VDI 6023 Blatt 1",
            fileUrl: "/downloads/VVDI 6023_Blatt 1_2022-09.pdf",
          },
          {
            fileName: "VDI 6023 Blatt 2",
            fileUrl: "/downloads/VDI 6023_Blatt 2_2018-01.pdf",
          },
          {
            fileName: "VDI 6023 Blatt 4",
            fileUrl: "/downloads/VDI 2070_03-2013-150316-tp.pdf",
          },
        ],
      },
    ],
  },
  {
    germanTitle: "Wartungsprotokolle",
    englishDescription: "maintenance_reports",
    files: [
      {
        fileName: "AC Wartungsprotokoll Absalzanlage",
        fileUrl:
          "/downloads/AC_Wartungsprotokoll_Absalzanlage V20.4_201022_ET.pdf",
      },
      {
        fileName: "AC Wartungsprotokoll Dosierpumpe",
        fileUrl:
          "/downloads/AC_Wartungsprotokoll_Dosierpumpe V20.4_201022_ET.pdf",
      },
      {
        fileName: "AC Wartungsprotokoll Druckerhöhung",
        fileUrl:
          "/downloads/AC_Wartungsprotokoll_Druckerhöhung V20.4_201022_ET.pdf",
      },
      {
        fileName: "AC Wartungsprotokoll Enthärtungsanlagen",
        fileUrl:
          "/downloads/AC_Wartungsprotokoll_Enthärtungsanlagen V20.4_201022_ET.pdf",
      },
    ],
  },
  {
    germanTitle: "Sonstiges",
    englishDescription: "miscellaneous",
    files: [
      {
        fileName: "Dekonaminationserklärung",
        fileUrl: "/downloads/Dekonaminationserklärung_20200624.pdf",
      },
    ],
  },
  {
    germanTitle: "Reinigungen",
    englishDescription: "miscellaneous",
    files: [
      {
        fileName: "Cleaning instruction for Decalcit T 610 and 131",
        fileUrl:
          "/downloads/Cleaning instruction for Decalcit T 610 and 131_EN.pdf",
      },
      {
        fileName: "DC131 610 Anwendungsblatt",
        fileUrl: "/downloads/DC131_610Anwendungsblatt.pdf",
      },
      {
        fileName: "AC Haftungsvereinbarung für spezielle Dienstleitungen",
        fileUrl:
          "/downloads/Haftungsvereinbarung für spezielle Dienstleitungen_AC.pdf",
      },
      {
        fileName: "AC Haftungsvereinbarung für spezielle Dienstleitungen-1",
        fileUrl:
          "/downloads/Haftungsvereinbarung für spezielle Dienstleitungen_AC-1.pdf",
      },
      {
        fileName: "Schicht Haftungsvereinbarung für spezielle Dienstleitungen",
        fileUrl:
          "/downloads/Haftungsvereinbarung für spezielle Dienstleitungen_SC.pdf",
      },
      {
        fileName: "AC Bedingungen für chemische Reinigung",
        fileUrl: "/downloads/Bedingungen für chemische Reinigung_AC.pdf",
      },
      {
        fileName: "Schicht Bedingungen für chemische Reinigung",
        fileUrl: "/downloads/Bedingungen für chemische Reinigung_SC.pdf",
      },
      {
        fileName: "Schicht Bedingungen für chemische Reinigung-1",
        fileUrl: "/downloads/Bedingungen für chemische Reinigung_SC-1.pdf",
      },
    ],
  },
];

export const coraconCategories: FileCategoryKunden[] = [
  {
    germanTitle: "WTF Geothermie",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/dummy.pdf",
  },
  {
    germanTitle: "WTF Solarthermie",
    englishDescription: "solar_energy",
    fileUrl: "/downloads/dummy.pdf",
  },
  {
    germanTitle: "WTF Gebäude- und Anlagentechnik",
    englishDescription: "building_technology",
    fileUrl: "/downloads/dummy.pdf",
  },
  {
    germanTitle: "Heizung",
    englishDescription: "heater",
    fileUrl: "/downloads/dummy.pdf",
  },
  {
    germanTitle: "Superkonzentrate",
    englishDescription: "super_concentrated",
    fileUrl: "/downloads/dummy.pdf",
  },
  {
    germanTitle: "Sonstiges",
    englishDescription: "miscellaneous",
    fileUrl: "/downloads/dummy.pdf",
  },
];

export const brochureSection: FileCategoryKunden[] = [
  {
    germanTitle: "AC Unternehmensvorstellung",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/aqua-concept Unternehmensvorstellung_20220414.pdf",
  },
  {
    germanTitle: "Broschüre Bioticare",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/AC_bioticare_Broschüre.pdf",
  },
  {
    germanTitle: "Broschüre Springbrunnen_2018",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/Broschüre Springbrunnen_2018.pdf",
  },
  {
    germanTitle: "Broschüre Kühlwasser_2018",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/Broschüre Kühlwasser_2018.pdf",
  },
  {
    germanTitle: "Broschüre Sprinkleranlagen_2019",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/Broschüre Sprinkleranlagen_2019.pdf",
  },
  {
    germanTitle: "Coracon VDI6044",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/AC_coracon_VDI6044.pdf",
  },
  {
    germanTitle: "Coracon´Service und Energie",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/AC_coracon_Service_und _Energie.pdf",
  },
  {
    germanTitle: "C3 - Careblue Control Center",
    englishDescription: "geothermal_energy",
    fileUrl: "/downloads/AC_Careblue Control Center_C3.pdf",
  },
];

export const enthSection: FileCategoryKunden[] = [
];

export const filterSection: FileCategoryKunden[] = [
];

export const umkehrosmoseSection: FileCategoryKunden[] = [
];

export const chemieSection: FileCategoryKunden[] = [
];

export const dosiertechnikSection: FileCategoryKunden[] = [
];

export const industrialWaterSection: FileCategoryKunden[] = [
  {
    germanTitle: "Ausschreibungstexte",
    englishDescription: "tender_documents",
    subSections: [
      {
        title: "Absalzplatten/Bypass VDI 6044",
        files:[]
      },
      {
        title: "Chemie",
        files: [
          {
            fileName: "LV Korrosionsschutz",
            fileUrl: "/downloads/LV Korrosionsschutz.pdf",
          },
          {
            fileName: "LV Stabilisatoren",
            fileUrl: "/downloads/LV Stabilisatoren.pdf",
          },
          {
            fileName: "LV Biozide & Dispergatoren",
            fileUrl: "/downloads/LV Biozide & Dispergatoren.pdf",
          },
          {
            fileName: "LV Kombiprodukte",
            fileUrl: "/downloads/LV Kombiprodukte.pdf",
          },
        ]
      },
      {
        title: "Enthärtungsanlagen",
        files:[
          {
            fileName: "DWF5-SX Tabelle",
            fileUrl: "/downloads/DWF5-SX Tabelle.png",
          },
          {
            fileName: "VAK CK Tabelle",
            fileUrl: "/downloads/VAK CK Tabelle.png",
          },
          {
            fileName: "VAS CK Tabelle",
            fileUrl: "/downloads/VAS CK Tabelle.png",
          },
          {
            fileName: "LV Enthärtungsanlagen",
            fileUrl: "/downloads/LV Enthärtungsanlagen.pdf",
          },
        ]
      },
      {
        title: "Filter",
        files:[
          {
            fileName: "LV Filteranlagen",
            fileUrl: "/downloads/LV Filteranlagen.pdf",
          }
        ]
      },
      {
        title: "Pumpen",
        files:[]
      },
      {
        title: "Sprinkler",
        files:[]
      },
      {
        title: "Umkehrosmose",
        files:[
          {
            fileName: "UOR Tabelle",
            fileUrl: "/downloads/UOR_Tabelle.png",
          },
          {
            fileName: "UON Tabelle3",
            fileUrl: "/downloads/UON_Tabelle3.png",
          },
          {
            fileName: "UON Tabelle2",
            fileUrl: "/downloads/UON_Tabelle2.png",
          },
          {
            fileName: "UON Tabelle",
            fileUrl: "/downloads/UON_Tabelle.png",
          },
          {
            fileName: "LV Umkehrosmoseanlagen",
            fileUrl: "/downloads/LV Umkehrosmoseanlagen.pdf",
          },
        ]
      },
      {
        title :"Sonstiges",
        files:[]
      },
      {
        title: "Dosiertechnik",
        files: [
          {
            fileName: "LV Dosiertechnik",
            fileUrl: "/downloads/LV Dosiertechnik.pdf",
          },
        ]
      }
    ]
  }
];


export const CoraconSection: FileCategoryKunden[] = [
  {
    germanTitle: "Ausschreibungstexte",
    englishDescription: "tender_documents",
    subSections: [
      {
        title: "WTF Geothermie",
        files:[]
      },
      {
        title: "WTF Solarthermie",
        files: []
      },
      {
        title: "WTF Gebäude- und Anlagentechnik",
        files:[]
      },
      {
        title: "Heizung",
        files:[]
      },
      {
        title: "Superkonzentrate",
        files:[]
      },
      {
        title: "Sonstiges",
        files:[]
      },
    ]
  }
];
