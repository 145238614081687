import { GridColDef } from "@mui/x-data-grid";
import i18n from '../../../i18n'
import { formatDateToGermanDate } from "../../../utils/helper";

export const columns: GridColDef[] = [
  // {
  //   field: "id",
  //   headerName: i18n.t("components:id"),
  //   flex: 1,
  //   valueGetter: (params) => (params.value ? params.value : "-"),
  // },
  {
    field: "user_name",
    headerName: i18n.t("components:user_name"),
    flex: 1,
    valueGetter: (params) => (params.value ? params.value : "-"),
  },
  {
    field: "old_values_all",
    headerName: i18n.t("components:changes"),
    flex: 2,
    valueGetter: (params) => {
      const row = params.row;
      let text = ""
      const changes = [] as any
      for (const [key1, value1] of Object.entries(row.old_values_all)) {
        for (const [key2, value2] of Object.entries(row.old_values_all[key1])) {
          changes[key2] = true
        }
      }
      for (const [key1, value1] of Object.entries(row.new_values_all)) {
        for (const [key2, value2] of Object.entries(row.new_values_all[key1])) {
          changes[key2] = true
        }
      }

      for (const key in changes) {
        text += i18n.t(`components:fields.${key}` as any) + ', ';
      }
      
      return text.length==0? '-': text.slice(0, -2) + ".";
    },
  },
  {
    field: "created_at",
    headerName: i18n.t("components:created_at"),
    flex: 1,
    valueGetter: (params) => (params.value ? formatDateToGermanDate(new Date(params.value)) : "-"),
  },
];
