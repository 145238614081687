import * as yup from "yup";

const changePasswordValidation = yup.object().shape({
  password: yup
    .string()
    .required("Passwort wird benötigt")
    .min(6, "Das Passwort muss mindestens aus 6 Zeichen bestehen"),
  password_confirmation: yup
    .string()
    .required("Eine Passwortbestätigung ist erforderlich")
    .test(
      "password-match",
      "Die Passwörter müssen übereinstimmen",
      function (value) {
        const { password } = this.parent;
        return password === value || value === null;
      }
    ),
});

export default changePasswordValidation;
