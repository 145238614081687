import { ApiService } from "./api.service";
import {
  CreateTaskModel,
  TaskModel,
  TaskOptions,
  TaskViewModel,
  TaskSettingsModel,
  ReportsModel,
  CreateVisitTaskModel,
  VisitHistoryModel,
  ViewVisitTaskModel,
  TaskOptionModel,
} from "../models/tasks/tasks.model";
import { IResponse } from "../models/response";
import { useQuery, useMutation, MutationFunction } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { saveAs } from "file-saver";
import { showErrorAlert } from "../components/shared/alerts";
import { token, BASE_URL } from "./index";
import { AssigneesUsers, ReportsToUsers } from "../models/users/users-by-roles.model";

// Get list of tasks
export const useFetchTasks = (
  buildingId?: string,
  page?: number,
  itemsPerPage: number = 10,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "tasks",
    buildingId,
    page,
    itemsPerPage,
    JSON.stringify(filters),
    sort,
  ];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params: Record<string, unknown> = {
      page: page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const tasksData = await ApiService.get(`/buildings/${buildingId}/tasks`, {
      params,
    });
    return tasksData?.data;
  });
  return { ...queryResult, refetch };
};

// Get list of assigned tasks
export const useFetchAssignedTasks = (
  page = 1,
  itemsPerPage = 10,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "assigned-tasks",
    page,
    itemsPerPage,
    JSON.stringify(filters),
    sort,
  ];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params = {
      page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const assignedTasksData = await ApiService.get(`/activities`, { params });
    return assignedTasksData.data;
  });
  return { ...queryResult, refetch };
};

// Get list of resolved tasks
export const useFetchResolvedTasks = (
  buildingId?: string,
  page = 1,
  itemsPerPage = 12,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "resolved-tasks",
    buildingId,
    page,
    itemsPerPage,
    JSON.stringify(filters),
    sort,
  ];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params = {
      page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const resolvedTasksData = await ApiService.get(
      `/buildings/${buildingId}/resolved-tasks`,
      { params }
    );
    return resolvedTasksData.data;
  });
  return { ...queryResult, refetch };
};

// Get list of task options
export const useFetchTaskOptions = () => {
  const queryKey = ["task-options"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskOptions>,
    AxiosError
  >(queryKey, async () => {
    const tasksData = await ApiService.get(`/task-options`);
    return tasksData;
  });
  return { ...queryResult, refetch };
};

// Create new task
export const useCreateTask = (buildingId?: string) => {
  const createTask: MutationFunction<
    IResponse<CreateTaskModel>,
    FormData
  > = async (formData: FormData) => {
    const response = await ApiService.postFormData(
      `/buildings/${buildingId}/tasks`,
      formData
    );
    return response.data;
  };
  return useMutation(createTask);
};

// Get Task Details
export const useGetTaskDetails = (taskId?: string) => {
  const queryKey = ["task", taskId];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskViewModel>,
    AxiosError
  >(queryKey, async () => {
    const taskData = await ApiService.get(`/tasks/${taskId}`);
    return taskData;
  });

  return { ...queryResult, refetch };
};

// Get template types
export const useFetchTemplateTypes = () => {
  const queryKey = ["template-types"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskOptionModel[]>,
    AxiosError
  >(queryKey, async () => {
    const typesData = await ApiService.get(`/tasks-templates`);
    return typesData;
  });
  return { ...queryResult, refetch };
};

// Get list of Task settings
export const useFetchTaskSettings = (
  buildingId?: string,
  page = 1,
  itemsPerPage = 12,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>
) => {
  const queryKey = [
    "setting-tasks",
    buildingId,
    page,
    itemsPerPage,
    JSON.stringify(filters),
    sort,
  ];
  const { refetch, ...queryResult } = useQuery<
    IResponse<TaskSettingsModel[]>,
    AxiosError
  >(queryKey, async () => {
    const params = {
      page,
      per_page: itemsPerPage,
      sortField: sort?.field,
      sortDirection: sort?.sort,
      ...filters,
    };
    const tasksData = await ApiService.get(
      `/buildings/${buildingId}/setting-tasks`,
      { params }
    );
    return tasksData?.data;
  });
  return { ...queryResult, refetch };
};

// Get mickro execel file

export const downloadAndSaveMikroExcelFile = async (
  taskId: string,
  type: "normal" | "follow-up",
  page: number
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/tasks/${taskId}/excel/${type}?page=${page}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );

    // Get the file name from the header
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition?.split('filename=')[1].replace(/"/g, '');

    if (response.status === 200) {
      saveAs(response.data, filename);
    } else {
      console.error(`Failed to fetch the Excel file for page ${page}`);
    }
  } catch (error: AxiosError | any) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = "Beim Herunterladen der Datei ist ein Fehler aufgetreten.";

        // Handle specific error codes
        switch (status) {
          case 400:
            errorMessage = data.message || "Ungültige Anfrage. Bitte überprüfen Sie Ihre Eingabe.";
            break;
          case 401:
            errorMessage = "Nicht autorisiert. Bitte melden Sie sich erneut an.";
            break;
          case 403:
            errorMessage = "Sie haben keine Berechtigung, auf diese Datei zuzugreifen.";
            break;
          case 404:
            errorMessage = "Die angeforderte Datei wurde nicht gefunden.";
            break;
          case 500:
            errorMessage = "Serverfehler. Bitte versuchen Sie es später erneut.";
            break;
          default:
            errorMessage = data?.message || "Ein unerwarteter Fehler ist aufgetreten.";
        }

        console.error(`Error ${status}: ${errorMessage}`);
        showErrorAlert(errorMessage);
      } else {
        // Any other error
        console.error("Fehler:", error.message);
        showErrorAlert("Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
      }
    }
  }
};

// Get Task Reports Details
export const useGetTaskReportsDetails = (taskId?: string) => {
  const queryKey = ["reports", taskId];
  const { refetch, ...queryResult } = useQuery<
    IResponse<ReportsModel>,
    AxiosError
  >(queryKey, async () => {
    const taskData = await ApiService.get(`/tasks/${taskId}/reports`);
    return taskData;
  });

  return { ...queryResult, refetch };
};

// end task
export const useUpdateTask = (taskId?: string) => {
  const endTaskDate: MutationFunction<IResponse<TaskViewModel>, any> = async (
    postData
  ) => {
    const response = await ApiService.patch(`/tasks/${taskId}`, postData);
    return response;
  };
  return useMutation(endTaskDate);
};

export const FetchReport = async (
  plantId?: string,
  filters?: Record<string, unknown>
): Promise<boolean> => {
  const url = `/plants/${plantId}/annual-report/no-auth`;

  try {
    await ApiService.get(url, { params: filters });
    return true; // Return true if the API call is successful
  } catch (error) {
    console.error("Error fetching report:", error); // Log the error for debugging
    return false; // Return false if an error occurs
  }
};

// Create visit task for client
export const useCreateVisitTask = (clientId?: string) => {
  const createVisitTask: MutationFunction<
    IResponse<CreateVisitTaskModel>,
    FormData
  > = async (formData: FormData) => {
    const response = await ApiService.postFormData(
      `/clients/${clientId}/visits`,
      formData
    );
    return response.data;
  };
  return useMutation(createVisitTask);
};

// Get list of visit history for client
export const useFetchVisitsHistory = (
  clientId?: string,
  page = 1,
  itemsPerPage = 12,
  filters?: Record<string, unknown>,
  sort?: Record<string, unknown>,
) => {
  const queryKey = [
    "task-history",
    clientId,
    page,
    itemsPerPage,
    JSON.stringify(filters),
    sort,
  ];
  const queryResult = useQuery<IResponse<VisitHistoryModel[]>, AxiosError>(
    queryKey,
    async () => {
      const params = {
        page,
        per_page: itemsPerPage,
        sortField: sort?.field,
        sortDirection: sort?.sort,
        ...filters,
      };
      const visitTasksData = await ApiService.get(
        `/clients/${clientId}/task-history`,
        { params }
      );
      return visitTasksData.data;
    }
  );
  return queryResult;
};

// Get Visit details
export const useGetVisitDetails = (id?: string) => {
  const queryKey = ["visits", id];

  const queryResult = useQuery<IResponse<ViewVisitTaskModel>, AxiosError>(
    queryKey,
    () => ApiService.get(`/visits/${id}`),
    {
      enabled: Boolean(id),
    }
  );

  return queryResult;
};

// Get assignees users
export const useFetchAssigneesUsers = () => {
  const queryKey = ["assignees-users"];
  const { refetch, ...queryResult } = useQuery<
    IResponse<AssigneesUsers[]>,
    AxiosError
  >(queryKey, async () => {
    const typesData = await ApiService.get(`/get-task-assignees`);
    return typesData;
  });
  return { ...queryResult, refetch };
};

export const useFetchTaskReportsToOptions = (taskId?: string, enabled: boolean = true) => {
  const queryKey = ["reports-to-options", taskId];
  const { refetch, ...queryResult } = useQuery<
    IResponse<ReportsToUsers[]>,
    AxiosError
  >(queryKey, async () => {
    const reportsToUsers = await ApiService.get(`/tasks/${taskId}/get-task-reports-to-options`);
    return reportsToUsers;
  },
  {
    enabled: !!taskId && enabled, // Ensures the query only runs if `taskId` exists and `enabled` is true
  });

  return { ...queryResult, refetch };
};