import React, { FunctionComponent } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "../shared/header";
import ChangePasswordForm from "../shared/forms/change-password";

interface UsersCahngeFormProps {}

const UsersCahngeForm: FunctionComponent<UsersCahngeFormProps> = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id } = useParams();

  return (
    <>
      <Box
        sx={{
          marginTop: isNonMobile ? "40px" : "60px",
        }}
      >
        <Header title={"Kennwort ändern"} />
        <ChangePasswordForm userId={id} />
      </Box>
    </>
  );
};

export default UsersCahngeForm;
