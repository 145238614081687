import React, { useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import DataTable from "../shared/table";
import { useDashboardTables } from "../../hooks/useDashboardTables";
import Can from "../../casl/Can";
import { useFetchUnreadNotifications } from "../../api/notification";
import { userId } from "../../api";
import { columns } from "../notifications/notification.columns";
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortItem,
} from "@mui/x-data-grid";
import { transformFilterItemToFilterObject } from "../../utils/helper";
import { useQueryClient } from "@tanstack/react-query";
import { Box } from "@mui/joy";
import Header from "../shared/header";

/**
 * Dashboard Component
 *
 * This React component displays the main dashboard page, including notifications and various tables of data.
 * It provides filtering, sorting, and pagination functionality for the notifications table and dynamically renders other dashboard tables based on permissions.
 *
 * @remarks
 * The component integrates with multiple APIs to fetch notifications and dashboard data.
 * It uses role-based access control (RBAC) to determine which tables to display for the user.
 * The notification table supports server-side filtering, sorting, and pagination.
 *
 *
 * @example
 * ```tsx
 * import Dashboard from "./Dashboard";
 *
 * <Dashboard />;
 * ```
 *
 * @returns {JSX.Element} A JSX element representing the dashboard.
 */

const Dashboard = () => {
  const tablesData = useDashboardTables();
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const queryClient = useQueryClient();
  const queryKey = ["unread-notifications-count"];
  // Filter
  const [filters, setFilters] = useState({});

  const [sort, setSort] = useState({});

  const {
    data: notificationsData,
    isLoading,
    refetch: refetchNotifications,
  } = useFetchUnreadNotifications(
    userId,
    currentPage,
    itemsPerPage,
    undefined,
    filters,
    sort
  );

  const notifications = notificationsData?.data;
  const totalItems = notificationsData?.pager?.total;

  /**
   * Handles changes in pagination for the notifications table.
   *
   * @param {GridPaginationModel} newPage - The new pagination model with page number and page size.
   */
  const handlePageChange = (newPage: GridPaginationModel) => {
    // Fetch new data from the server based on the new page
    setCurrentPage(newPage.page + 1);
    setItemsPerPage(newPage.pageSize);
  };

  /**
   * Handles changes in the filter model for the notifications table.
   *
   * @param {GridFilterItem} filterItem - The filter item to apply.
   */
  const handleFilterModelChange = (filterItem: GridFilterItem) => {
    const filterObject = transformFilterItemToFilterObject(filterItem);
    setFilters(filterObject);
  };

  /**
   * Handles changes in the sort model for the notifications table.
   *
   * @param {GridSortItem} sortItem - The sort item to apply.
   */
  const handleSortModelChange = (sortItem: GridSortItem) => {
    setSort(sortItem);
  };

  /**
   * Refetches the notifications data and invalidates the query cache.
   */
  const handleRefetch = () => {
    refetchNotifications();
    queryClient.invalidateQueries(queryKey);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginTop: "60px",
          mb: 2,
        }}
      >
        <Header title="Benachrichtigungen" />
      </Box>
      <DataTable
        data={notifications ?? []}
        fieldsNames={columns}
        boxHeight={75.5}
        notifications={true}
        currentPage={currentPage - 1}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
        refetch={handleRefetch}
        ssrFilter={true}
        isLoading={isLoading}
        onFilterChange={handleFilterModelChange}
        onSortChange={handleSortModelChange}
        ssrSort={true}
        showFilterPanel={true}
      />

      <Grid
        container
        columnSpacing={2}
        rowSpacing={5}
        mb={2}
        mt={{ xs: 5, lg: 0 }}
      >
        {tablesData.map((table, index) => (
          <Can key={index} I="view" a={table.permission}>
            <Grid item xs={12} lg={6}>
              <Typography variant="h4" fontWeight="bold" mb={2}>
                {table.title}
              </Typography>
              <DataTable
                data={table.rows ?? []}
                fieldsNames={table.columns}
                noDataMessage="Keine zugewiesene Aufgabe für Sie"
                currentPage={table.paginationModel.data.page - 1}
                totalItems={table.paginationModel.data.total}
                itemsPerPage={table.paginationModel.data.pageSize}
                isLoading={table.isLoading}
                parentPath={table?.parentPath}
                boxHeight={75}
                ssrFilter={true}
                ssrSort={true}
                onPageChange={(page) =>
                  table.paginationModel.setPagination({
                    page: page.page + 1,
                    pageSize: page.pageSize,
                  })
                }
                onFilterChange={(f) =>
                  table.filterModel.setFilter({ items: [f] })
                }
                onSortChange={table.sortModel.setSort}
                customLink={table?.customLink}
              />
            </Grid>
          </Can>
        ))}
      </Grid>
      <Outlet />
    </>
  );
};

export default Dashboard;
