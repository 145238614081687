import { GridColDef } from "@mui/x-data-grid";
import { formatDateToGermanDate } from "../../utils/helper";

export const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Titel",
    flex: 1.5,
  },
  {
    field: "type",
    headerName: "Typ",
    flex: 0.5,
  },
  {
    field: "sent_at",
    headerName: "Gesendet am",
    flex: 1,
    valueGetter: ({ row, value }) => {
      return value ? formatDateToGermanDate(new Date(value)) : '-';
    },
  },
];
